<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="3" :total="3" scan />

		<mdb-container>

			<p class="font-weight-normal mb-5 px-4">{{ $t('newApplication.coBorrowerConfirmationText') }}</p>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.coBorrowerInfo') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<router-link tag="div" :to="{name: 'CoBorrowerStep1', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<template v-for="(fieldData, fieldName) in getForm('1')">

						<div class="form-group static-form-group">

							<label>{{ $t('newApplication.' + fieldName) }}</label>
							<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
							<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

						</div>

					</template>

				</mdb-card-body>
			</mdb-card>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.coBorrowerAddress') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<router-link tag="div" :to="{name: 'CoBorrowerStep2', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<div><!-- used this div to remove border because of last-child selector -->
						<template v-for="(fieldData, fieldName) in getForm('2')">

							<template v-if="fieldName === 'row'">

								<div v-for="(childData, childName) in fieldData.fields" class="form-group static-form-group">

									<label>{{ getLabel(childName) }}</label>
									<p class="form-control-plaintext">{{ values[childName] || '&mdash;' }}</p>
									<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

								</div>

							</template>

							<div v-else class="form-group static-form-group">

								<label>{{ $t('newApplication.' + fieldName) }}</label>
								<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
								<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

							</div>

						</template>
					</div>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					@click="goBack({name: 'CoBorrowerStep2', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.submit') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import CoBorrowerMixin from './CoBorrowerMixin';
	import FormsConfirmationMixin from '../FormsConfirmationMixin';

	export default {

		mixins: [
			CoBorrowerMixin,
			FormsConfirmationMixin
		],

		watch: {

			'coBorrower.data': {
				handler(val) {

					if (val && Object.keys(val).length) {

						this.setValues();

					}

				},
				deep: true,
				immediate: true
			}

		},

		methods: {

			getFieldError(name) {

				return this.coBorrower.errors && this.coBorrower.errors[name];

			}

		}

	};

</script>

<style lang="scss" scoped>

	.edit-btn {
		position: relative;
		top: -1rem;
		right: -1rem;
	}

	.card-body {

		.form-group.static-form-group {

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}

		}

	}

	.form-control-plaintext {
		white-space: pre-line;
	}

</style>
