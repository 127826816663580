const Dashboard = () => import(/* webpackChunkName: "application" */ '../application/components/Dashboard');
const List = () => import(/* webpackChunkName: "application" */ './components/List');
const Event = () => import(/* webpackChunkName: "application" */ './components/Event');
const FinancingProgram = () => import(/* webpackChunkName: "application" */ './components/FinancingProgram');
const PaymentCalculator = () => import(/* webpackChunkName: "application" */ './components/PaymentCalculator');
const Assets = () => import(/* webpackChunkName: "application" */ './components/Assets/ListAssets');
const AddAsset = () => import(/* webpackChunkName: "application" */ './components/Assets/AddAsset');
const EditAsset = () => import(/* webpackChunkName: "application" */ './components/Assets/EditAsset');
const UploadFilesStep1 = () => import(/* webpackChunkName: "application" */ '../application/components/UploadFilesStep1');
const UploadFilesStep2 = () => import(/* webpackChunkName: "application" */ '../application/components/UploadFilesStep2');
const Sign = () => import(/* webpackChunkName: "application" */ '../application/components/Sign');

const Step1 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step1');
const Step2 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step2');
const Step31 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step3-1');
const Step32 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step3-2');
const Step33 = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step3-3');
const Step4Confirmation = () => import(/* webpackChunkName: "application-form" */ './components/ApplicationForms/Application/Step4Confirmation');

const WaitingSubmission = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/WaitingSubmission');
const PendingApproval = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/PendingApproval');
const NotApproved = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/NotApproved');
const Approved = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/Approved');
const CreditBureauNotFound = () => import(/* webpackChunkName: "application-response" */ './components/ApplicationForms/CreditBureauNotFound');

const CoBorrowerStep0 = () => import(/* webpackChunkName: "coborrower-form" */ './components/ApplicationForms/CoBorrower/Step0');
const CoBorrowerStep1 = () => import(/* webpackChunkName: "coborrower-form" */ './components/ApplicationForms/CoBorrower/Step1');
const CoBorrowerStep2 = () => import(/* webpackChunkName: "coborrower-form" */ './components/ApplicationForms/CoBorrower/Step2');
const CoBorrowerStep3Confirmation = () => import(/* webpackChunkName: "coborrower-form" */ './components/ApplicationForms/CoBorrower/Step3Confirmation');

export default [

	{
		name: 'Dashboard',
		path: '',
		component: Dashboard,
		meta: {requiresAuth: true}
	},

	{
		name: 'FinancingProgram',
		path: 'financing-program/:applicationId?',
		component: FinancingProgram,
		meta: {requiresAuth: true}
	},

	{
		name: 'PaymentCalculator',
		path: 'payment-calculator/',
		component: PaymentCalculator,
		meta: {requiresAuth: true}
	},

	{
		name: 'Sign',
		path: 'sign/:applicationId',
		component: Sign,
		meta: {requiresAuth: true}
	},

	{
		name: 'Assets',
		path: 'assets/:applicationId',
		component: Assets,
		meta: {requiresAuth: true}
	},

	{
		name: 'AddAsset',
		path: 'assets/:applicationId/new',
		component: AddAsset,
		meta: {requiresAuth: true}
	},

	{
		name: 'EditAsset',
		path: 'assets/:applicationId/:assetId',
		component: EditAsset,
		meta: {requiresAuth: true}
	},

	{
		name: 'UploadFiles',
		path: 'upload-files',
		redirect: 'upload-files/new',
		meta: {requiresAuth: true}
	},

	{
		name: 'UploadFilesStep1',
		path: 'upload-files/:applicationId',
		component: UploadFilesStep1,
		meta: {requiresAuth: true}
	},

	{
		name: 'UploadFilesStep2',
		path: 'upload-files/:type/:applicationId',
		component: UploadFilesStep2,
		meta: {requiresAuth: true}
	},

	{
		name: 'Applications',
		path: 'applications',
		redirect: {name: 'ApplicationList', params: {status: 'all'}},
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationList',
		path: 'applications/:status',
		component: List,
		meta: {requiresAuth: true}
	},

	/*
	 * Application Form
	 */

	{
		name: 'Application',
		path: 'application',
		redirect: 'application/1',
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep1',
		path: 'application/1/:applicationId?',
		component: Step1,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep2',
		path: 'application/2/:applicationId?',
		component: Step2,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep3',
		path: 'application/3/:applicationId?',
		redirect: 'application/3-1/:applicationId?',
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep3.1',
		path: 'application/3-1/:applicationId?',
		component: Step31,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep3.2',
		path: 'application/3-2/:applicationId?',
		component: Step32,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep3.3',
		path: 'application/3-3/:applicationId?',
		component: Step33,
		meta: {requiresAuth: true}
	},

	{
		name: 'ApplicationStep4',
		path: 'application/4/:applicationId?',
		component: Step4Confirmation,
		meta: {requiresAuth: true}
	},

	/*
	 * Waiting Submit & Status Pages
	 */

	{
		name: 'WaitingSubmission',
		path: 'application/submitting/:applicationId?',
		component: WaitingSubmission,
		meta: {requiresAuth: true}
	},

	{
		name: 'PendingApproval',
		path: 'application/pending/:applicationId?',
		component: PendingApproval,
		meta: {requiresAuth: true}
	},

	{
		name: 'NotApproved',
		path: 'application/not-approved/:applicationId?',
		component: NotApproved,
		meta: {requiresAuth: true}
	},

	{
		name: 'Approved',
		path: 'application/approved/:applicationId?',
		component: Approved,
		meta: {requiresAuth: true}
	},

	{
		name: 'CreditBureauNotFound',
		path: 'application/credit-bureau-not-found/:applicationId?',
		component: CreditBureauNotFound,
		meta: {requiresAuth: true}
	},

	/*
	 * CoBorrower Form
	 */

	{
		name: 'CoBorrower',
		path: 'application/coborrower',
		redirect: 'application/coborrower/0/:applicationId?',
		meta: {requiresAuth: true}
	},

	{
		name: 'CoBorrowerStep0',
		path: 'application/coborrower/0/:applicationId?',
		component: CoBorrowerStep0,
		meta: {requiresAuth: true}
	},

	{
		name: 'CoBorrowerStep1',
		path: 'application/coborrower/1/:applicationId?',
		component: CoBorrowerStep1,
		meta: {requiresAuth: true}
	},

	{
		name: 'CoBorrowerStep2',
		path: 'application/coborrower/2/:applicationId?',
		component: CoBorrowerStep2,
		meta: {requiresAuth: true}
	},

	{
		name: 'CoBorrowerStep3',
		path: 'application/coborrower/3/:applicationId?',
		component: CoBorrowerStep3Confirmation,
		meta: {requiresAuth: true}
	},

	/*
	 * Application Event
	 */

	{
		name: 'ApplicationEvent',
		path: 'application/:event/:applicationId',
		component: Event,
		meta: {requiresAuth: true}
	},

	/*
	 * Other Pages
	 */

	/*
	// to get back file, see commit f2cd38e76fc6293667e76037b5263e1043d72699:
	// https://github.com/incodeapps/reliance-uei/blob/f2cd38e76fc6293667e76037b5263e1043d72699/vue/src/app/application/components/Share.vue
	{
		name: 'Share',
		path: 'share/:applicationId?',
		component: Share,
		meta: {requiresAuth: true}
	},
	*/

];
