import { mapActions, mapState } from 'vuex';
import { isFunction } from '@incodeapps/js-utilities';
import { getApplication } from '../../../api';
import store from '../../../store';

export default {

	data() {

		return {
			applicationInDB: null,
			applicationId: null,
			applicationHasCoApplicant: null,
			backBtnFlag: false,
			submitting: false,
			goingBack: false,
			infoMessage: null
		};

	},

	computed: {

		...mapState({
			locale: state => state.app.locale,
			application: state => state.application.info,
			coBorrower: state => state.application.coBorrower
		})

	},

	watch: {

		'$store.state.application.info': {
			handler(val, oldVal) {

				this.watchAppVariables(val, oldVal);

			},
			deep: true,
			immediate: true
		}

	},

	created() {

		if (this.$route.name === 'ApplicationStep1' && this.$route.params.applicationId === 'new') {

			this.resetApp();

		} else {

			this.loadAppFromIdInUrl();

		}

	},

	async beforeDestroy() {

		await this.removeAlert(this.infoMessage);
		await this.removeAlert(this.errorAlert);

	},

	methods: {

		...mapActions([
			'saveLoading',
			'showErrorAlert',
			'showSuccessAlert',
			'showInfoAlert',
			'removeAlerts',
			'removeAlert',
			'throwFatalError',
			'saveApplicationAction',
			'resetApplicationAction',
			'saveCoBorrowerAction'
		]),

		getLabel(fieldName) {

			if (/^row/.test(fieldName)) {

				return '';

			}

			return this.$i18n.t('newApplication.' + fieldName);

		},

		async queryApplication(id) {

			return await getApplication(id).catch(error => {

				if (error.response.data && error.response.data.errorMessage === "EdgeId could not be found") {

					this.throwFatalError(error.response.data.errorMessage);

				} else {

					this.throwFatalError(this.$i18n.t('fatalError.getApplication'));

				}

			});

		},

		async queryAndSave() {

			this.saveLoading(true);

			this.applicationInDB = await this.queryApplication(this.$route.params.applicationId);

			if (!this.applicationInDB || !this.applicationInDB.application) {

				// console.debug('Resetting the application cookie because can\'t find it');
				this.resetApp();

				let errorMsg = this.$i18n.t('fatalError.getApplication');
				this.throwFatalError(errorMsg);

				this.saveLoading(false);
				return;

			}

			let promises = [];
			let appData = Object.assign({}, this.applicationInDB.application);

			if (this.applicationInDB.coApplicant && Object.keys(this.applicationInDB.coApplicant).length) {

				// appData.hasCoApplicant = 'Yes';

				promises.push(this.saveApplicationAction({
					data: appData,
					errors: {}
				}));

				promises.push(this.saveCoBorrowerAction({
					data: Object.assign({}, this.applicationInDB.coApplicant),
					errors: {}
				}));

			} else {

				// appData.hasCoApplicant = 'No';

				promises.push(this.saveApplicationAction({
					data: appData,
					errors: {}
				}));

			}

			await Promise.all(promises);

			this.watchAppVariables(this.application);

			if (this.amountApproved !== undefined) {

				this.amountApproved = this.application.data.amountApproved;
				this.rentalAmount = this.application.data.rentalAmount;
				this.appId = this.application.data.edgeId;

			}

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				this.setDefaultValues(this.vModels, this.application.data);

			} else if (/^CoBorrowerStep(.*?)$/.test(this.$route.name)) {

				this.setDefaultValues(this.vModels, this.coBorrower.data);

			}

			if (isFunction(this.setValues)) {

				// only in FormsConfirmationMixin
				this.setValues();

			}

			if (['Approved', 'NotApproved', 'CreditBureauNotFound', 'PendingApproval'].includes(this.$route.name)) {

				if (!this.applicationId || !this.application.data || !this.application.data.status) {

					// console.debug('The status page cannot exist without the application id');
					this.resetApp();

					let errorMsg = this.$i18n.t('fatalError.getApplication');
					return this.throwFatalError(errorMsg);

				}

				let redirect;

				switch (this.application.data.status) {

					case 'Incomplete':
						redirect = 'ApplicationStep1';
						break;

					case 'Bureau Not Found':
					case 'BureauNotFound':
						redirect = 'CreditBureauNotFound';
						break;

					case 'Not Approved':
					case 'Declined':
						redirect = 'NotApproved';
						break;

					case 'Pending':
						redirect = 'PendingApproval';
						break;

					default:
						redirect = this.application.data.status;
						break;

				}

				if (!['Approved', 'NotApproved', 'CreditBureauNotFound', 'PendingApproval'].includes(redirect)) {

					return this.$router.push({
						name: 'Dashboard'
					});

				}

				if (this.$route.name !== redirect) {

					return this.$router.push({
						name: redirect,
						params: this.$route.params
					});

				}

			} else if (/^CoBorrowerStep(.*?)$/.test(this.$route.name) && !this.applicationId) {

				// console.debug('The coBorrower form cannot exist without the application id');
				this.resetApp();

				let errorMsg = this.$i18n.t('fatalError.getApplication');
				this.throwFatalError(errorMsg);

			}

			this.saveLoading(false);

		},

		watchAppVariables(val, oldVal) {

			this.applicationId = val && val.data && val.data.id;

			this.applicationHasCoApplicant = (val && val.data && val.data.hasCoApplicant && val.data.hasCoApplicant === 'Yes');

			if (this.applicationId && oldVal && oldVal.data && oldVal.data.id && this.applicationId !== oldVal.data.id && this.$route.params.applicationId !== this.applicationId) {

				this.throwFatalError(this.$i18n.t('fatalError.cookieAppChanged'));

			}

			this.showReSubmitInfoAlert(val, oldVal);

		},

		async showReSubmitInfoAlert(val, oldVal) {

			if (val.data.status && (!oldVal || !oldVal.data || !oldVal.data.status || val.data.status === oldVal.data.status)) {

				if (
					(/^ApplicationStep1$/.test(this.$route.name) || /^CoBorrowerStep0$/.test(this.$route.name))
					&& (val.data && val.data.status && val.data.status !== 'Incomplete')
				) {

					let title = this.$i18n.t('applications.aboutResubmitText');
					let message = this.$i18n.t('applications.aboutResubmit');

					if (/^CoBorrowerStep0$/.test(this.$route.name) && !this.applicationHasCoApplicant) {

						title = this.$i18n.t('applications.aboutResubmitWithNewCoBorrower');
						message = this.$i18n.t('applications.aboutResubmitTextWithNewCoBorrower');

					}

					await this.removeAlert(this.infoMessage);

					this.infoMessage = await this.showInfoAlert({
						title: title,
						message: message
					});

				}

			}

		},

		async goBack(route) {

			this.goingBack = true;

			await this.removeAlerts();

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				await this.saveApplicationAction({
					data: this.vModels
				});

			} else if (/^CoBorrowerStep(.*?)$/.test(this.$route.name)) {

				await this.saveCoBorrowerAction({
					data: this.vModels
				});

			}

			await this.$router.push(route);

			this.goingBack = false;

		},

		async resetApp() {

			this.resetApplicationAction();
			this.applicationId = null;
			return true;

		},

		async loadAppFromIdInUrl() {

			if (!this.$route.params.applicationId || this.$route.params.applicationId === 'new') {

				return false;

			}

			if (this.$route.params.applicationId !== this.applicationId) {

				// console.log('Resetting because id mismatch');
				this.resetApplicationAction();
				this.applicationId = null;

			}

			if (
				!this.applicationId
				|| /^PendingApproval$/.test(this.$route.name)
				|| /^NotApproved$/.test(this.$route.name)
				|| /^Approved$/.test(this.$route.name)
				|| /^CreditBureauNotFound$/.test(this.$route.name)
			) {

				this.queryAndSave();

			}

		},

		async checkAppStatus() {

			let result = getApplication(this.$route.params.applicationId);

		}

	}

};
