<template>

	<mdb-container class="mt-5">

		<mdb-card>
			<mdb-card-body class="card-body-padding text-center">

				<p class="mb-4">
					<icon name="clock" type="uei-icon" class="text-primary huge-uei-icon">
						<span class="path1"></span>
						<span class="path2"></span>
						<span class="path3"></span>
						<span class="path4"></span>
						<span class="path5"></span>
						<span class="path6"></span>
						<span class="path7"></span>
						<span class="path8"></span>
						<span class="path9"></span>
						<span class="path10"></span>
						<span class="path11"></span>
						<span class="path12"></span>
						<span class="path13"></span>
						<span class="path14"></span>
					</icon>
				</p>

				<h3 class="font-weight-bold uppercase text-danger mt-4">{{ $t('newApplication.pendingTitle') }}</h3>

				<p class="my-4">
					{{ $t('newApplication.pendingText') }}
					<template v-if="!applicationHasCoApplicant">
						<br />{{ $t('newApplication.applyCoborrower') }}
					</template>
				</p>

			</mdb-card-body>
		</mdb-card>

		<div class="form-group button-row" v-if="!applicationHasCoApplicant">

			<btn
				type="button"
				color="primary"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'Dashboard'})"
			>{{ $t('forms.no') }}</btn>

			<btn
				type="button"
				color="info"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'CoBorrowerStep0', params: $route.params})"
			>{{ $t('forms.yes') }}</btn>

		</div>

		<div class="form-group button-row" v-else>

			<btn
				type="button"
				color="primary"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'Dashboard'})"
			>{{ $t('newApplication.homeBtn') }}</btn>

			<btn
				type="button"
				color="info"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'ApplicationStep1', params: $route.params})"
			>{{ $t('newApplication.reviewBtn') }}</btn>

		</div>

	</mdb-container>

</template>

<script>

	import { getStatus, isCancel } from '../../../api';
	import BaseMixin from './BaseMixin';

	export default {

		mixins: [
			BaseMixin
		],

		data() {

			return {
				refreshTimeout: null
			};

		},

		mounted() {

			this.refreshStatusLookupLoop();

		},

		methods: {

			async refreshStatusLookupLoop() {

				if (this.refreshTimeout) {

					return;

				}

				let result = await getStatus(this.$route.params.applicationId).catch(error => {

					console.debug('refreshStatusLookupLoop error', error);

				});

				if (result && result.status) {

					if (this.redirectBasedOnStatus(result.status)) {

						this.clearTimeout();
						return;

					}

				}

				this.refreshTimeout = setTimeout(() => {

					this.clearTimeout();

					this.refreshStatusLookupLoop();

				}, 1000 * 10);


			},

			clearTimeout() {

				clearTimeout(this.refreshTimeout);
				this.refreshTimeout = null;

			},

			redirectBasedOnStatus(status) {

				if (!status) {

					this.throwFatalError(`Internal Server Error`);

				}

				switch (status) {

					case 'Approved':
						return this.$router.push({
							name: 'Approved',
							params: {
								applicationId: this.applicationId
							}
						});

					case 'Declined':
					case 'NotApproved':
					case 'Not Approved':
						return this.$router.push({
							name: 'NotApproved',
							params: {
								applicationId: this.applicationId
							}
						});

					case 'Bureau Not Found':
					case 'BureauNotFound':
						return this.$router.push({
							name: 'CreditBureauNotFound',
							params: {
								applicationId: this.applicationId
							}
						});

				}

			}

		}

	};

</script>

<style lang="scss" scoped>

</style>
