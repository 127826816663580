<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="4" :total="4" />

		<mdb-container>

			<p class="font-weight-normal mb-5 px-4">{{ $t('newApplication.confirmationText') }}</p>

			<mdb-card>
				<mdb-card-body>

					<router-link tag="div" :to="{name: 'ApplicationStep1', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<template v-for="(fieldData, fieldName) in getForm('1pre')">

						<div :class="getGroupClasses(fieldName)">

							<label>{{ $t('newApplication.' + fieldName) }}:</label>
							<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
							<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

						</div>

					</template>

					<template v-for="(fieldData, fieldName) in getForm('1')">

						<div :class="getGroupClasses(fieldName)">

							<label>{{ $t('newApplication.' + fieldName) }}:</label>
							<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
							<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

						</div>

					</template>

				</mdb-card-body>
			</mdb-card>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.mainApplicant') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<router-link tag="div" :to="{name: 'ApplicationStep3.1', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<div><!-- used this div to remove border because of last-child selector -->

						<!--<div :class="getGroupClasses('licenseNum')">

							<label>{{ $t('newApplication.licenseNum') }}:</label>
							<p class="form-control-plaintext">{{ values.licenseNum || '&mdash;' }}</p>
							<div v-if="getFieldError('licenseNum')" class="invalid-feedback">{{ getFieldError('licenseNum') }}</div>

						</div>-->

						<template v-for="(fieldData, fieldName) in getForm('3.1')">

							<div :class="getGroupClasses(fieldName)">

								<label>{{ $t('newApplication.' + fieldName) }}</label>
								<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
								<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

							</div>

						</template>

						<template v-if="isVendorFromQuebec">

							<template v-for="(fieldData, fieldName) in getForm('3.1quebec')">

								<div :class="getGroupClasses(fieldName)">

									<label>{{ $t('newApplication.' + fieldName) }}</label>
									<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
									<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

								</div>

							</template>

						</template>

					</div>

				</mdb-card-body>
			</mdb-card>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.currentAddress') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<router-link tag="div" :to="{name: 'ApplicationStep3.2', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<div><!-- used this div to remove border because of last-child selector -->
						<template v-for="(fieldData, fieldName) in getForm('3.2')">

							<template v-if="fieldName === 'row'">

								<div v-for="(childData, childName) in fieldData.fields" :class="getGroupClasses(childName)">

									<label>{{ getLabel(childName) }}</label>
									<p class="form-control-plaintext">{{ values[childName] || '&mdash;' }}</p>
									<div v-if="getFieldError(childName)" class="invalid-feedback">{{ getFieldError(childName) }}</div>

								</div>

							</template>

							<div v-else :class="getGroupClasses(fieldName)">

								<label>{{ $t('newApplication.' + fieldName) }}</label>
								<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
								<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

							</div>

						</template>
					</div>

					<hr style="border: 1px lightgrey dashed; margin: 2rem 0 3rem;" />

					<router-link tag="div" :to="{name: 'ApplicationStep3.3', params: $route.params}">
						<btn
							tag="a"
							flat
							class="text-info float-right edit-btn"
						><icon name="edit" type="uei-icon" class="font-lg"></icon></btn>
					</router-link>

					<template v-for="(fieldData, fieldName) in getForm('3.3pre')">

						<div :class="getGroupClasses(fieldName)">

							<label>{{ $t('newApplication.' + fieldName) }}:</label>
							<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
							<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

						</div>

					</template>

					<template v-if="values.curAddressLess === 'Yes'">
						<template v-for="(fieldData, fieldName) in getForm('3.3')">

							<template v-if="fieldName === 'row'">

								<div v-for="(childData, childName) in fieldData.fields" :class="getGroupClasses(childName)">

									<label>{{ getLabel(childName) }}</label>
									<p class="form-control-plaintext">{{ values[childName] || '&mdash;' }}</p>
									<div v-if="getFieldError(childName)" class="invalid-feedback">{{ getFieldError(childName) }}</div>

								</div>

							</template>

							<div v-else :class="getGroupClasses(fieldName)">

								<label>{{ $t('newApplication.' + fieldName) }}</label>
								<p class="form-control-plaintext">{{ values[fieldName] || '&mdash;' }}</p>
								<div v-if="getFieldError(fieldName)" class="invalid-feedback">{{ getFieldError(fieldName) }}</div>

							</div>

						</template>
					</template>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<template v-if="isResubmitWithoutCoBorrower">

					<submit-btn
						type="button"
						color="primary"
						class="next-to-button-col"
						:loading="goingBack"
						@click="goBack({name: 'ApplicationStep3.3', params: $route.params})"
					>{{ $t('forms.back') }}</submit-btn>

					<div class="buttons-col">

						<submit-btn
							:loading="submitting"
						>{{ $t('forms.submit') }}</submit-btn>

						<span class="or">OR</span>

						<submit-btn
							type="button"
							style="min-width: 210px;"
							:loading="otherBtnLoading"
							@click="otherBtnClick()"
						>{{ $t('newApplication.coborrowerFormBtnReSubmit') }}</submit-btn>
					</div>

				</template>

				<template v-else>

					<submit-btn
						type="button"
						color="primary"
						:loading="goingBack"
						@click="goBack({name: 'ApplicationStep3.3', params: $route.params})"
					>{{ $t('forms.back') }}</submit-btn>

					<submit-btn type="submit" v-if="applicationHasCoApplicant" :loading="submitting" style="min-width: 210px;">{{ $t('newApplication.coborrowerFormBtn') }}</submit-btn>

					<submit-btn type="submit" v-else :loading="submitting">{{ $t('forms.submit') }}</submit-btn>

				</template>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import { mapState } from 'vuex';
	import ApplicationMixin from './ApplicationMixin';
	import FormsConfirmationMixin from '../FormsConfirmationMixin';

	export default {

		mixins: [
			ApplicationMixin,
			FormsConfirmationMixin
		],

		data() {

			return {
				otherBtnLoading: false
			};

		},

		computed: {

			isResubmitWithoutCoBorrower() {

				return  (
					this.application.data
					&& this.application.data.status
					&& this.application.data.status !== 'Incomplete'
					&& !this.applicationHasCoApplicant
				);

			}

		},

		watch: {

			'application.data': {
				handler(val) {

					if (val) {

						this.setValues();

					}

				},
				deep: true,
				immediate: true
			}

		},

		methods: {

			getFieldError(name) {

				return this.application.errors && this.application.errors[name];

			},

			otherBtnClick() {

				this.otherBtnLoading = true;

				this.$router.push({name: 'CoBorrowerStep0', params: this.$route.params});
				return;

			}

		}

	};

</script>

<style lang="scss" scoped>

	.edit-btn {
		position: relative;
		top: -1rem;
		right: -1rem
	}

	.card-body {

		.form-group.static-form-group {

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}

		}

		.invalid-feedback {
			margin-top: -1rem;
			margin-bottom: 1rem;
		}

	}

	.form-control-plaintext {
		white-space: pre-line;
	}

</style>
