<template>

	<mdb-container class="mt-5">

			<mdb-card>
				<mdb-card-body>

					<h1 class="mb-5">UEI financial™️ Digital Accessibility Statement</h1>

					<p><strong>Reliance™</strong> has been providing affordable, reliable home heating and cooling to Canadians for more than 50 years. Our customers know they can count on us for fast, friendly, knowledgeable service and advice – and that’s comfortable!</p>

					<p>We are committed to digital accessibility, and to conforming to the <a href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noopener noreferrer">Web Content Accessibility Guidelines (WCAG)</a> 2.0, Level A and AA and complying with the Accessibility for Ontarians with Disabilities Act (AODA) effective communication requirements, and other applicable regulations.</p>

					<p>To accomplish this, we are working with eSSENTIAL Accessibility to administer our accessibility program and oversee its governance. Their accessibility program evaluates our digital products on an ongoing basis in accordance with best practices and is supported by a diverse team of accessibility professionals, including users of assistive technologies. The platform, moreover, goes beyond minimum compliance requirements by making an <a href="https://www.essentialaccessibility.com/reliance-home-comfort?utm_source=reliancehomepage&amp;utm_medium=iconlarge&amp;utm_term=eachannelpage&amp;utm_content=header&amp;utm_campaign=reliancehomecomfort" target="_blank" rel="noopener noreferrer">assistive CX technology application</a> available to customers who have trouble typing, gesturing, moving a mouse, or reading. The application can be downloaded at no cost and it incorporates tools such as mouse and keyboard replacements, voice recognition, speech enablement, hands-free/touch-free navigation, and more.</p>

					<p><strong>Contact Reliance with your accessibility questions.</strong><br>
					When you select a link from our web pages, which leads to a third party managed website, you are subject to the privacy, copyright, security, and information quality policies of that website and we cannot guarantee that the website will comply with the accessibility requirements of Section 508 of the Rehabilitation Act and the Web Content Accessibility Guidelines (WCAG).<br>
					Our efforts are ongoing. If at any time you encounter any accessibility barriers on our digital properties or have questions about the accessibility of any particular web page, please contact us via email at <a href="mailto:HRinfo@reliancecomfort.com">HRinfo@reliancecomfort.com</a> or by phone: 1-888-837-1451</p>

				</mdb-card-body>
			</mdb-card>

	</mdb-container>

</template>

<script>

	export default {

		components: {},

		data() {

			return {};

		},

		created() {},

		beforeDestroy() {},

		methods: {}

	};

</script>
