import { login, logout, session, changePassword } from '../../api';
import { i18n } from '../../i18n';
import moment from 'moment';
import router from '../../router';

let checkSessionTimeout;

export const loginAction = async ({ commit }, payload) => {

	let res = await login(payload);

	if (res) {

		commit('SAVE_AUTH_USER_TOKEN', res.data.authToken);
		commit('SAVE_AUTH_USER', res.data.user);
		commit('SAVE_AUTH_USER_VENDOR', res.data.vendor);
		commit('SET_AUTH_USER_TOKEN_LAST_CHECK', true);

		return true;

	}

};

export const logoutAction = async ({ commit }) => {

	logout();

	commit('SAVE_AUTH_USER_TOKEN', null);
	commit('SAVE_AUTH_USER', null);
	commit('SET_AUTH_USER_TOKEN_LAST_CHECK', null);

	return true;

};

export const forcedLogoutAction = async ({ dispatch }, showAlert) => {

	if (showAlert !== false) {

		await dispatch('showErrorAlert', i18n.t('fatalError.tokenExpired'));
	
	}

	await dispatch('logoutAction');

	await router.push({
		name: 'Login',
		query: (router.currentRoute.fullPath !== '/') ? {redirect: encodeURI(router.currentRoute.fullPath)} : {}
	});

	return true;

};

const isTokenValid = async () => {

	let result = await session();

	if (!result) {

		return false;

	}

	return result;

};

export const checkAuthUserToken = async ({ commit, state, dispatch }) => {

	if (!state.authUserToken) {

		return false;

	}

	let alreadyLoggedOut = false;
	let now = moment();
	let xMinAfterLastCheck = moment(state.authUserTokenLastCheck).add(2, 'minutes');

	if (!state.authUserTokenLastCheck || now.isSameOrAfter(xMinAfterLastCheck)) {

		let result = await isTokenValid().catch(error => {

			console.debug('isTokenValid error', JSON.stringify(error, null, 2));

			if (error.response && error.response.status === 403) {

				alreadyLoggedOut = true;

			}

		});

		if (!result) {

			if (!alreadyLoggedOut) {

				console.debug('isTokenValid forcedLogoutAction');
				dispatch('forcedLogoutAction');

			}

			/*dispatch('showErrorAlert', i18n.t('fatalError.tokenExpired'));*/

			commit('SET_AUTH_USER_TOKEN_LAST_CHECK', true);

			return false;

		}

		if (result.Item) {

			// console.log('[checkAuthUserToken] SESSION RESULT IS', result.Item);

		}

		commit('SET_AUTH_USER_TOKEN_LAST_CHECK', false);

	}

	if (!checkSessionTimeout) {

		checkSessionTimeout = setTimeout(() => {

			checkSessionTimeout = null;
			dispatch('checkAuthUserToken');

		}, 1000 * 60 * 4);

	}

	return (state.authUserToken);

};

export const changePasswordAction = async ({ commit }, payload) => {

	let res = await changePassword(payload);

	if (res) {

		return res;

	}

};
