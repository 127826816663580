<template>

	<form ref="form" @submit.prevent="onSubmit" novalidate>

		<steps :current="2" :total="4" />

		<mdb-container>

			<mdb-card>
				<mdb-card-body class="card-body-padding text-center">

					<h3 class="font-weight-bold mt-2 mb-4">{{ $t('newApplication.scanLicense') }}</h3>

					<div v-if="isVendorFromQuebec">
						<p class="text-danger">{{ $t('newApplication.scanLicenseDisabled') }}</p>
					</div>

					<div v-else>

						<p class="col-md-20 mx-auto" v-if="$t('newApplication.scanLicenseText')">{{ $t('newApplication.scanLicenseText') }}</p>

						<div class="mt-5 mb-3">

							<p v-if="!scanning">

								<img :src="ScanSvg" width="100%" height="auto" />

							</p>

							<div id="scandit-barcode-picker" ref="scanner" v-show="showScanner"></div>

						</div>

					</div>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group text-center">

				<div v-if="!isVendorFromQuebec" style="margin-bottom: 1.8rem">

					<submit-btn
						v-if="showContinue"
						type="button"
						@click="continueScanning"
						key="scan-continue-btn"
					>{{ $t('newApplication.continueScanning') }}
					</submit-btn>

					<submit-btn
						v-else-if="scanning"
						type="submit"
						key="scan-pause-btn"
						@click="pauseScanning"
					>{{ $t('newApplication.pauseScanning') }}
					</submit-btn>

					<submit-btn
						v-else
						type="button"
						:loading="scanning"
						key="scan-now-btn"
						@click="scanNow"
					>{{ $t('newApplication.scanNow') }}
					</submit-btn>

				</div>

				<submit-btn
					color="white text-info"
					spinner-color="info"
					key="skip-btn"
					:loading="submitting"
				>{{ $t('newApplication.skipStep') }}
				</submit-btn>

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					key="back-btn"
					@click="goBack({name: 'ApplicationStep1', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from './ApplicationMixin';
	import ScanMixin from '../ScanMixin';
	import ScanSvg from '../../../../../assets/img/scan.svg';

	export default {

		mixins: [
			ApplicationMixin,
			ScanMixin
		],

		data() {

			return {
				ScanSvg
			};

		}

	};

</script>

<style lang="scss" scoped>

	.icon-scan {
		font-size: 18rem;
	}

	#scandit-barcode-picker {
		max-height: 80vh;
		position: relative;
		margin-left: -1rem;
		margin-right: -1rem;
		margin-top: -1rem;
	}

	/deep/ .scandit-barcode-picker {
		min-height: 150px;
		min-width: 150px;

		@include media-breakpoint-up(sm) {
			min-height: 250px;
			min-width: 250px;
		}

		@include media-breakpoint-up(md) {
			min-height: 350px;
			min-width: 350px;
		}

		@include media-breakpoint-up(lg) {
			min-height: 400px;
			min-width: 400px;
		}

	}

</style>
