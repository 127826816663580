const AuthLayout = () => import(/* webpackChunkName: "auth" */ './components/Layout');
const Login = () => import(/* webpackChunkName: "auth" */ './components/Login');
const Logout = () => import(/* webpackChunkName: "auth" */ './components/Logout');
const ChangePassword = () => import(/* webpackChunkName: "auth" */ './components/ChangePassword');
const AccessibilityPolicy = () => import(/* webpackChunkName: "auth" */ './components/AccessibilityPolicy');

export default [
	
	{
		path: '/auth',
		component: AuthLayout,
		redirect: '/login',
		children: [
			
			{
				name: 'Login',
				path: 'login',
				component: Login
			},
			
			{
				name: 'Logout',
				path: 'logout',
				component: Logout,
				meta: {requiresAuth: true}
			}
			
		]
	}

];

export const AfterAuthRoutes = [
	
	{
		name: 'ChangePassword',
		path: 'change-password',
		component: ChangePassword,
		meta: {requiresAuth: false}
	},
	
	{
		name: 'AccessibilityPolicy',
		path: 'accessibility-policy',
		component: AccessibilityPolicy,
		meta: {requiresAuth: false}
	}
	
];
