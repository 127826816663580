<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="1" :total="4" />

		<mdb-container>

			<mdb-card>
				<mdb-card-body>

					<fieldset>

						<div class="form-group required">

							<label>{{ $t('newApplication.creditReleaseObtained') }}</label>

							<div class="border rounded p-4">

								<p><em>{{ $t('newApplication.creditReleaseObtainedText0') }}</em></p>

								<label for="creditReleaseObtained" class="label-default-text mb-3">{{ $t('newApplication.creditReleaseObtainedText1') }} <strong>{{ $t('newApplication.creditReleaseObtainedText2') }}</strong></label>

								<template v-for="(fieldData, fieldName) in getForm('1pre')">

									<form-field
										v-bind="checkForOptions(fieldName, fieldData)"
										v-model="vModels[fieldName]"
										:name="fieldName"
										:label="false"
										:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
										:error="errors[fieldName]"
										:locale="locale"
										bootstrap
										@change="onChange"
										wrapper-class="mb-1"
									></form-field>

								</template>

							</div>

						</div>

						<template v-for="(fieldData, fieldName) in getForm('1')">

							<form-field
								v-bind="fieldData"
								v-model="vModels[fieldName]"
								:name="fieldName"
								:label="getLabel(fieldName)"
								:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
								:error="errors[fieldName]"
								:locale="locale"
								bootstrap
								@change="onChange"
							></form-field>

						</template>

					</fieldset>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from './ApplicationMixin';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				vModels: {
					creditReleaseObtained: null,
					amount: null,
					applicationType: null,
					description: '',
					hasCoApplicant: 'No'
				}
			};

		},

		watch: {

			'vModels.amount'(val) {

				// console.log('=============================', val);

			}

		}

	};

</script>

<style lang="scss" scoped>

</style>
