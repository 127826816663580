<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="1" :total="3" scan />

		<mdb-container>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.coBorrowerInfo') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<div class="form-group required">

						<label>{{ $t('newApplication.coborrowerCreditReleaseObtained') }}</label>

						<div class="border rounded p-4">

							<p><em>{{ $t('newApplication.coborrowerCreditReleaseObtainedText0') }}</em></p>

							<label for="creditReleaseObtained" class="label-default-text mb-3">{{ $t('newApplication.coborrowerCreditReleaseObtainedText1') }} <strong>{{ $t('newApplication.coborrowerCreditReleaseObtainedText2') }}</strong></label>

							<template v-for="(fieldData, fieldName) in getForm('1pre')">

								<form-field
									v-bind="checkForOptions(fieldName, fieldData)"
									v-model="vModels[fieldName]"
									:name="fieldName"
									:label="false"
									:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
									:error="errors[fieldName]"
									:locale="locale"
									bootstrap
									@change="onChange"
									wrapper-class="mb-1"
								></form-field>

							</template>

						</div>

					</div>

					<template v-for="(fieldData, fieldName) in getForm('1')">

						<form-field
							v-bind="checkForOptions(fieldName, fieldData)"
							v-model="vModels[fieldName]"
							:name="fieldName"
							:label="getLabel(fieldName)"
							:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
							:error="errors[fieldName]"
							:locale="locale"
							:mask="getMask(fieldName, fieldData)"
							bootstrap
							@change="onChange"
						></form-field>

					</template>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					@click="goBack({name: 'CoBorrowerStep0', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import CoBorrowerMixin from './CoBorrowerMixin';

	export default {

		mixins: [
			CoBorrowerMixin
		],

		data() {

			return {
				vModels: {
					creditReleaseObtained: null,
					isImmediateFamily: 'Yes',
					firstName: '',
					lastName: '',
					sin: '',
					coborrowerRelationship: null,
					dob: '',
					primaryPhone: '',
					mobilePhone: '',
					email: '',
					employer: '',
					businessPhone: ''
				},
				optionals: ['sin', 'mobilePhone', 'email']
			};

		}

	};

</script>

<style lang="scss" scoped>

</style>
