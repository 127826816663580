import { mapActions, mapState } from 'vuex';
import FormsMixin from '../FormsMixin';
import ApplicationConfig from './ApplicationConfig';

export default {

	mixins: [
		FormsMixin
	],

	computed: {

		...mapState({
			applicationSections: state => state.application.sections
		}),

		formsJson() {

			return ApplicationConfig;

		}

	},

	mounted() {

		let status = this.application.data.status;
		console.log(status);

		let notAllowed = [
			'Waiting for Signature',
			'Signatures Received',
			'Documents Received',
			'Complete Booking'
		];
	
		// if (notAllowed.includes(status)) {
		// 	this.showErrorAlert("You can't resubmit an approved application.");
		// 	return this.$router.push({name: 'Dashboard'});
		// }
		
		if (this.vModels && this.application.data) {

			this.setDebugValues(this.vModels, this.application.data);
			this.setDefaultValues(this.vModels, this.application.data);

		}

	},

	methods: {

		...mapActions([
			'saveApplicationAction',
			'resetApplicationAction',
			'submitApplicationAction',
			'saveApplicationSections'
		]),

		async onSubmit() {

			this.submitting = true;
			await this.removeAlerts();

			// if page contains vModels check for errors and save both values and errors to cookie, send to api /save

			if (this.vModels) {

				this.errors = this.validateRequiredFields(this.vModels, true);

				await this.saveApplicationAction({
					data: this.vModels,
					errors: this.errors
				});

				if (Object.keys(this.errors).length && !this.isScanPage) {

					this.errorAlert = this.showErrorAlert(this.$i18n.t('fatalError.errorAppForm'));

					this.submitting = false;

					return;

				}

			}

			// if no errors came up

			this.saveLoading(true);

			// for the steps I use a regex to extract the number in the current step path
			// it was supposed to only increment but it has many exceptions

			let match = this.$route.name.match(/^ApplicationStep(.*?)$/);
			let num = match[1];
			let dashed;
			
			switch (num) {

				case '1':
					num = this.isVendorFromQuebec ? '3.1' : '2';
					break;

				case '2':
					num = '3.1';
					break;

				case '3.3':
					num = '4';
					break;

				case '4':

					if (this.applicationHasCoApplicant) {

						return this.$router.push({
							name: this.isVendorFromQuebec ? 'CoBorrowerStep1' : 'CoBorrowerStep0',
							params: {
								applicationId: this.applicationId
							}
						});

					}

					return this.$router.push({
						name: 'WaitingSubmission',
						params: {
							applicationId: this.applicationId
						}
					});

				// the num should increment to the next step but
				// if it has a dash in it then increment the num after the dash
				default:

					dashed = num.match(/^(\d+)\.(\d+)$/);

					if (!dashed) {

						num = (parseInt(num, 10) + 1).toString();
						break;

					}

					num = dashed[1] + '.' + (parseInt(dashed[2], 10) + 1).toString();

					break;

			}

			return this.$router.push({
				name: `ApplicationStep${num}`,
				params: {
					applicationId: this.applicationId
				}
			});

		},

		setDefaults(vModels) {

			this.setDefaultValues(vModels, this.application.data);

		}

	}

};
