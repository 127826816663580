<template>

	<div class="databoxes-pre-row">

		<div class="databoxes-filter-col-search" v-if="searchShow || $slots.toolsRight">

			<form class="databoxes-filter" v-if="searchShow">

				<label class="sr-only mr-1">{{ $t('applications.search') }}</label>

				<input
					v-model="searchTerm"
					type="search"
					class="form-control form-control-sm"
					@input="onSearchInput"
					ref="search"
				/>

				<slot name="toolsRight"></slot>

			</form>

		</div>

		<div class="databoxes-filter-col-limit" v-if="perPageSelect || displayStyleBtns || $slots.toolsLeft">

			<form class="form-inline">

				<div class="databoxes-length bs-select" v-if="perPageSelect">

					<label class="mr-1">{{ $t('applications.limitShow') }}</label>

					<select
						v-model="perPageModel"
						class="custom-select custom-select-sm form-control form-control-sm"
						@change="onPageChange"
					>
						<option v-for="option in perPageOptions" :key="option" :selected="option === perPageModel">{{ option }}</option>
					</select>

				</div>

				<div class="btn-group" v-if="displayStyleBtns">

					<btn
						v-for="(btn, index) in displayStyleOptions"
						:key="'datatable-display-style-btn-' + index"
						type="button"
						size="sm"
						color="white"
						@click="$emit('update-display-style', btn.name)"
						:active="displayStyle === btn.name"
						:aria-label="btn.name"
					>
						<icon type="fa" :name="btn.icon" size="lg"></icon>
					</btn>

				</div>

				<slot name="toolsLeft"></slot>

			</form>

		</div>

	</div>

</template>

<script>

	import { throttle } from 'throttle-debounce';

	export default {

		name: 'DatatableTools',

		props: {

			locale: {
				type: String,
				default: 'en'
			},

			displayStyle: {
				type: String,
				default: 'grid'
			},

			displayStyleBtns: {
				type: Boolean,
				default: true
			},

			pagination: {
				type: [Number, Boolean],
				default: true
			},

			perPage: {
				type: [Boolean, Number, String],
				default: 4
			},

			perPageSelect: {
				type: Boolean,
				default: true
			},

			search: {
				type: [Boolean, String],
				default: true
			},

			sorting: {
				type: Boolean,
				default: true
			},

			searchInUrl: {
				type: Boolean,
				default: false
			}

		},

		data() {

			return {
				displayStyleOptions: [
					{name: 'grid', icon: 'th'},
					{name: 'list', icon: 'list'}
				],
				perPageOptions: [4, 8, 16, 32, 64, 128],
				perPageModel: 4,
				searchShow: true,
				searchTerm: '',
				searchEscaped: ''
			};

		},

		watch: {

			search: {
				handler(val) {

					val = (val === true) ? '' : val;

					if (val || val === '') {

						this.$set(this.$data, 'searchTerm', val);
						this.$set(this.$data, 'searchShow', true);

					} else {

						this.$set(this.$data, 'searchTerm', '');
						this.$set(this.$data, 'searchShow', false);

					}

				},
				immediate: true
			},

			searchTerm: {
				handler(val) {
					this.addToUrl(val);
				},
				immediate: true
			},

			perPage: {
				handler(val) {

					if (typeof val === 'string' || typeof val === 'number') {

						this.$set(this.$data, 'perPageModel', '' + val);

					}

				},
				immediate: true
			}

		},

		mounted() {

			if (this.searchInUrl) {

				this.loadSearch(this.$route);

			}

		},

		methods: {

			onPageChange: throttle(500, function (event) {

				this.updatePerPage(event.target.value);

			}),

			updatePerPage(val) {

				this.$emit('update-per-page', val);

			},

			loadSearch(to) {

				if (to.query && to.query.q) {

					this.updateSearch(to.query.q);

				}

			},

			onSearchInput: throttle(500, function (event) {

				this.updateSearch(event.target.value);

			}),

			updateSearch(value) {

				this.addToUrl(value);

				this.searchEscaped = this.escapeRegExp(value);

				this.$emit('update-search', this.searchEscaped);

			},

			escapeRegExp(string) {

				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

			},

			addToUrl(value) {

				if (this.searchInUrl) {

					this.insertParam('q', value);

				}

			},

			removeURLParameter(url, parameter) {

				//function to remove query params from a URL

				//better to use l.search if you have a location/link object
				var urlparts= url.split('?');
				if (urlparts.length>=2) {

					var prefix= encodeURIComponent(parameter)+'=';
					var pars= urlparts[1].split(/[&;]/g);

					//reverse iteration as may be destructive
					for (var i= pars.length; i-- > 0;) {

						//idiom for string.startsWith
						if (pars[i].lastIndexOf(prefix, 0) !== -1) {

							pars.splice(i, 1);

						}

					}

					url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");

				}

				return url;

			},

			insertParam(key, value) {

				if (history.pushState) {

					let currentUrl = window.location.href;
					//remove any param for the same key
					currentUrl = this.removeURLParameter(currentUrl, key);

					let urlLastPart = currentUrl.split('/').pop();

					// figure out if we need to add the param with a ? or a &
					let queryStart = (urlLastPart.indexOf('?') !== -1) ? '&' : '?' ;

					if (queryStart === '?' && !value.trim().length) {

						window.history.pushState({ path: currentUrl }, '', currentUrl);

						return;

					}

					let newurl = currentUrl + queryStart + key + '=' + value;
					window.history.pushState({ path:newurl }, '', newurl);

				}

			}

		}

	};

</script>

<style lang="scss" scoped>

	select, input {
		display: inline-flex !important;
		width: auto;
	}

	label {
		display: inline-block;
		margin-bottom: 0;
	}

	input[type=search] {
		background-color: #FFF;
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: auto 78%;
		padding-right: 2rem;
		$svgColor: 'ced4da';
		$svgColor: '00599A';
		$svg: "<svg title='search' fill='%23" + $svgColor + "'  xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'><title>Search</title><desc>Search</desc><g><path d='M10.37 9.474L7.994 7.1l-.17-.1a3.45 3.45 0 0 0 .644-2.01A3.478 3.478 0 1 0 4.99 8.47c.75 0 1.442-.24 2.01-.648l.098.17 2.375 2.373c.19.188.543.142.79-.105s.293-.6.104-.79zm-5.38-2.27a2.21 2.21 0 1 1 2.21-2.21A2.21 2.21 0 0 1 4.99 7.21z'/></g></svg>";
		background-image: url("data:image/svg+xml;utf8," + $svg);

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

	}

	.databoxes-length {
		margin-right: 1rem;

		select {
			width: 4.15rem;
			padding-right: 1.75rem;
			background-position: right 0.75rem center;
		}
	}

	.databoxes-pre-row {
		box-sizing: border-box;
		margin: 0 -15px;
		display: flex;
		flex-wrap: wrap;

		.databoxes-filter-col-search, .databoxes-filter-col-limit {
			width: 100%;
			margin: 0 0 1rem;
			margin-left: 15px;
			margin-right: 15px;

			@include media-breakpoint-up(md) {
				flex: 0 0 calc(50% - 2rem);

				&.databoxes-filter-col-search {
					order: 2;
					text-align: right;
				}

				&.databoxes-filter-col-limit {
					order: 1;
				}
			}

		}

	}

</style>
