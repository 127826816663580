<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="2" :total="4" label="Financing Programs"/>

		<mdb-card class="under-nav">

			<mdb-container>

				<mdb-card-body>

					<label for="program" class="h4 font-weight-bold">{{ $t("financingProgram.programType") }}</label>
					<form-field
						v-model="vModels.programType"
						name="programType"
						type="btn-group"
						:label="$t('financingProgram.program')"
						bootstrap
					>

						<btn
							v-for="programType in programTypes"
							:key="programType.value"
							type="button"
							:value="programType.value"
							color="primary"
							:active="programType.value === vModels.programType"
							class="p-4"
							@click.native="vModels.programType = programType.value"
						>

							<span>{{ programType.text }}</span>

						</btn>

					
					</form-field>

					<br>

					<label for="program" class="h4 font-weight-bold">{{ $t("financingProgram.program") }}</label>
					<form-field
						v-model="vModels.program"
						name="program"
						type="btn-group"
						:label="$t('financingProgram.program')"
						bootstrap
					>

						<btn
							v-for="program in programs"
							:key="program.value"
							type="button"
							:value="program.value"
							color="primary"
							:active="program === vModels.program"
							class="p-4 program-btn"
							@click.native="vModels.program = program"
						>
							<span class="text-uppercase">
								<span class="d-inline text-2rem- small">{{ program.description }}</span>
								<!-- <span class="d-inline text-2rem- small">{{ program.description }}</span> {{ $t('financingProgram.month') }} -->
							</span>

							<span class="text-3rem py-3 rate">{{ program.rateBump }} %</span>
							<!-- <span>{{ (program.programType ) ? $t('financingProgram.fixedRate') : '' }}</span> -->
							<span>{{ (program.programType ) }}</span>
							<span>{{ program.deferralLabel != 'None' ? program.deferralLabel : '' }}</span>
						</btn>

					</form-field>

				</mdb-card-body>

			</mdb-container>

		</mdb-card>

		<mdb-container>

			<mdb-row>
				<mdb-col>

					<mdb-card>
						<mdb-card-body v-if="vModels.program">

							<fieldset>

								<legend>{{ $t('financingProgram.amount', {amount: vModels.financedAmount }) }}</legend>
								<legend>{{ $t('financingProgram.deferralPeriod') }}: {{ vModels.deferralPeriod || 'N/A' }}</legend>
								<legend>{{ $t('financingProgram.rate') }}: {{ vModels.rate }}%</legend>

								<label for="term" class="h5 font-weight-bold mt-4 mb-0">{{ $t("financingProgram.term") }}</label>
								<form-field
									v-model="vModels.term"
									name="terms"
									type="btn-group"
									:label="$t('financingProgram.program')"
									bootstrap
								>

									<btn
										v-for="(term, index) in paymentTerm"
										:key="index"
										type="button"
										:value="index"
										color="primary"
										:active="index === vModels.term"
										class="p-4"
										@click.native="vModels.term = index"
									>
										<span class="text-uppercase">
											<span class="d-block text-2rem- small">{{ Number((term.en).replace(" months", "")) - vModels.qtyZeroPayments + ' ' + $t('financingProgram.months') }}</span><br>
											<span class="d-block text-2rem">${{ term.amount }}</span>
											<!-- <span class="d-inline text-2rem- small">{{ program.description }}</span> {{ $t('financingProgram.month') }} -->
										</span>

									</btn>

								</form-field>

								<br>

								<label for="leaseDate" class="h5 font-weight-bold">{{ $t("financingProgram.paymentDate") }}:</label>

								<form-field
									v-model="vModels.term"
									name="terms"
									type="btn-group"
									:label="$t('financingProgram.program')"
									bootstrap
								>

									<btn
										v-for="(leaseDate, index) in leaseDateOptions"
										:key="index"
										type="button"
										:value="leaseDate"
										color="primary"
										:active="leaseDate.value === vModels.leaseDate"
										class="p-3 lease-date"
										@click.native="vModels.leaseDate = leaseDate.value"
									>
										<span class="text-uppercase-">
											<span class="d-block text-2rem">{{ leaseDate.text }}</span>
										</span>

									</btn>

								</form-field>

								<br>

								<label for="installationDate" class="h5 font-weight-bold">{{ $t("financingProgram.installationDate") }}:</label>
								<form-field
									v-model="vModels.installationDate"
									name="installationDate"
									type="tel"
									placeholder="mm/dd/yyyy"
									mask="##/##/####"
									bootstrap
									required
								>
								</form-field>

								<!-- <form-field
									v-model="vModels.downpayment"
									name="downpayment"
									type="money"
									:placeholder="$t('financingProgram.downpayment')"
									bootstrap
								></form-field> -->

								<!-- <form-field
									v-model="vModels.deferralRate"
									name="deferralRate"
									type="select"
									:options="stores"
									:placeholder="$t('financingProgram.deferralRate')"
									bootstrap
								></form-field> -->

								<!-- <legend>{{ $t('financingProgram.monthly', {amount: vModels.monthly}) }}</legend> -->

							</fieldset>


						</mdb-card-body>
					</mdb-card>

					<div class="form-group mt-4 button-row">

						<submit-btn
							tabindex="2"
							@click="$router.push({name: 'Assets', params: $route.params})"
						>{{ $t('forms.back') }}</submit-btn>

						<submit-btn
							type="submit"
							tabindex="1"
							v-if="true || vModels.monthly && vModels.leaseDate"
							:disabled="!vModels.term"
							@click="saveProgram()"
						>{{ $t('forms.saveNext') }}</submit-btn>

					</div>

				</mdb-col>
			</mdb-row>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from './ApplicationForms/Application/ApplicationMixin';
	import { getPrograms, getProgramOptions, getMonthlyPayment } from '../../api';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				paymentTerm: [
					{text: 'Select Financing Program first', value: 'default'}
				],
				programs: [],
				programTypes: [
					{text: 'Non-deferred', value: 'nonDeferred'},
					{text: 'Deferred', value: 'deferred'},
					// {text: 'Buy Down', value: 'buyDown'}
				],
				leaseDateOptions: [
					{text: '1st', value: '1'},
					{text: '8th', value: '8'},
					{text: '15th', value: '15'},
					{text: '22nd', value: '22'}
				],

				submitting: false,
				isLoading: true,

				deferred: {},
				nonDeferred: {},
				buyDown: {},

				vModels: {
					program: null,
					financedAmount: null,
					programType: 'nonDeferred',
					term: null,
					rate: null,
					deferralPeriod: null,
					monthly: null,
					paymentTerm: "default",
					leaseDate: "default",
					installationDate: ''
				}

			};

		},

		watch: {

			'vModels.leaseDate': {
				async handler(val) {
					console.log('vModels.leaseDate', val);
				}
			},

			'vModels.programType': {

				async handler(val) {

					// this.saveLoading(true);

					console.log('vModels.programType', val);
					this.programs = null;

					await setInterval(() => {
						// workaround to this.programs not being properly replaced
					}, 500);

					if (val == 'nonDeferred') {

						this.programs = this.nonDeferred;

					} else if (val == 'deferred') {

						this.programs = this.deferred;

					} else {
						
						this.programs = this.buyDown;

					}

					// this.saveLoading(false);

				}

			},

			'vModels.program': {

				async handler(val) {

					this.saveLoading(true);
				
					console.log('vModels.program', val);
					console.log('application', this.application.data.id);

					let payload = {
						id: this.$route.params.applicationId, // this.application.data.id,
						programId: val.programId
					};

					await this.getProgramOptions(payload);
					// console.log('programOptions', result);

					this.saveLoading(false);

				}

			},

			'vModels.term': {

				async handler(val) {

					console.log('vModels.term', val);
					// // console.log('vModels', this.vModels.program.programId);

					// if (val != "default") {

					// 	await this.getMonthlyPayment({
					// 		termId: val,
					// 		leaseDate: this.vModels.leaseDate,
					// 		programId: this.vModels.program.programId
					// 	});
					
					// }

					// this.saveLoading(false);

				}

			}

		},

		mounted() {

			this.saveLoading(true);

			this.getPrograms();
			// this.vModels.financedAmount = this.application.financedAmount;

			this.saveLoading(false);

			setTimeout(function () {
				console.log('mounted()');
				// this.vModels.leaseDate = ;
			}, 0);

		},

		methods: {

			async saveProgram() {

				// console.log('saveProgram leaseDate', this.vModels.leaseDate);

				await this.getMonthlyPayment({
					termId: this.vModels.term,
					leaseDate: this.vModels.leaseDate,
					programId: this.vModels.program.programId,
					sendToEdge: true,
					installationDate: this.vModels.installationDate
				});

				this.$router.push({name: 'Sign', params: this.$route.params});

			},

			async getPrograms() {

				this.isLoading = true;
				this.submitting = true;

				let result = await getPrograms(this.$route.params.applicationId).catch(async error => {

					this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				console.log('result.programs', result.programs);

				if (result.assetCount == 0) {
					await this.showErrorAlert(this.$i18n.t('financingProgram.addAssetsPlease'));
					this.$router.push({name: 'Assets', params: this.$route.params});
				}

				let deferred = {};
				let nonDeferred = {};
				let buyDown = {};

				Object.keys(result.programs).forEach(key => {
					
					let program = result.programs[key];

					console.log(
						'program.description',
						program.description
					)

					console.log(
						'program.description.indexOf(Buy-Down) > -1',
						program.description.indexOf('Buy-Down') > -1,
						program.description.indexOf('Buydown') > -1
					)

					if (
						program.description.indexOf('Buy-Down') > -1
						|| program.description.indexOf('Buydown') > -1
					) {
						
						buyDown[program.programId] = program;
						
					} else if (
							program.deferralLabel
						&& program.deferralLabel.indexOf('None') > -1
					) {
						
						nonDeferred[program.programId] = program;

					} else {
						
						deferred[program.programId] = program;

					}

				});

				if (Object.keys(buyDown).length > 0) {
					this.programTypes.push(
						{text: 'Buy Down', value: 'buyDown'}
					);
					this.buyDown = buyDown;
				}

				this.deferred = deferred;
				this.nonDeferred = nonDeferred;

				console.log('this.deferred', this.deferred);
				console.log('this.nonDeferred', this.nonDeferred);
				console.log('this.buyDown', this.buyDown);

				this.programs = null;
				this.programs = {};
				console.log('after nulling this.programs', this.programs);
				this.programs = this.nonDeferred;
				console.log('after assigning this.programs', this.programs);

			},

			async getProgramOptions(programId) {

				this.submitting = true;

				let result = await getProgramOptions(programId).catch(async error => {

					this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				if (result.deferralPeriods) {

					this.vModels.deferralPeriod = result.deferralPeriods.en;
					this.vModels.qtyZeroPayments = result.deferralPeriods.qtyZeroPayments;

				} else {
					
					this.vModels.deferralPeriod = '';
					this.vModels.qtyZeroPayments = '';

				}

				this.vModels.financedAmount = result.financedAmount.toFixed(2);
				
				this.vModels.rate = result.rates.name;

				// result.terms['default'] = {
				// 	en: "Choose Payment Term"
				// };

				console.log('result.terms', result.terms);

				this.paymentTerm = null;

				this.removeAlerts();

				await setInterval(() => {
				// workaround to this.programs not being properly replaced
				}, 500);

				this.paymentTerm = result.terms;

			},
			
			async getMonthlyPayment(data) {

				this.isLoading = true;
				this.submitting = true;

				this.saveLoading(true);

				let today = new Date();
				let leaseDate; // format: mm/dd/yyyy

				if (today.getDate() >= data.leaseDate) {

					if ((today.getMonth() + 1) == 12) { // if Dec
						leaseDate = '01/' + data.leaseDate + '/' + (today.getFullYear() + 1);
					} else {
						leaseDate = (today.getMonth() + 2) + '/' + data.leaseDate + '/' + today.getFullYear();
					}

				} else {

					leaseDate = (today.getMonth() + 1) + '/' + data.leaseDate + '/' + today.getFullYear();

				}

				let payload = {
					termId: data.termId,
					leaseDate: leaseDate,
					programId: data.programId,
					id: this.application.data.id,
					sendToEdge: data.sendToEdge,
					installationDate: data.installationDate
				};

				let result = await getMonthlyPayment(payload).catch(async error => {

					this.saveLoading(false);

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.errorAlert = await this.showErrorAlert(errorMsg);

					}

				});

				if (result.results.monthlyPayment) {

					this.vModels.monthly = result.results.monthlyPayment;
				
				} else {

					this.errorAlert = await this.showErrorAlert(result);
				
				}

				this.saveLoading(false);

			},

			onSubmit(e) {

				this.isLoading = true;
				this.submitting = true;

				console.debug(
					'[onSubmit] event:', e,
					'\nvModels:', JSON.stringify(this.vModels, null, 2)
				);

			}

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ .btn-group {
		display: flex;
		flex-wrap: wrap;

		.btn {
			border-radius: $btn-border-radius !important;
			width: 30%;
			max-width: 31.5%;
			margin: 1rem 1%!important;

			&.lease-date {
				// margin: 1rem 1%;
				width: 10%;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0.2rem 0%;
					padding: 10px!important;
				}
			}

			@media (max-width: 767px) {
				width: 100%;
				margin: 0.5rem 0%;

				&.program-btn {
					padding: 10px!important;
				}

				span.rate {
					font-size: 2rem!important;
				}

			}



			span {
				display: block;
			}

			&.btn-primary {
				&.active, &:active {
					background-color: $info !important;
				}
			}

		}

	}

</style>
