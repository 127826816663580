import FormsMixin from '../FormsMixin';
import CoBorrowerConfig from './CoBorrowerConfig';

export default {

	mixins: [
		FormsMixin
	],

	computed: {

		formsJson() {

			return CoBorrowerConfig;

		}

	},

	mounted() {

		if (this.vModels && this.coBorrower.data) {

			this.setDebugValues(this.vModels, this.coBorrower.data);
			this.setDefaultValues(this.vModels, this.coBorrower.data);

		}

	},

	methods: {

		async onSubmit() {

			this.submitting = true;
			await this.removeAlerts();

			// if page contains vModels check for errors and save both values and errors to cookie, send to api

			if (this.vModels) {

				this.errors = this.validateRequiredFields(this.vModels, true);

				await this.saveCoBorrowerAction({
					data: this.vModels,
					errors: this.errors
				});

				if (Object.keys(this.errors).length) {

					this.errorAlert = this.showErrorAlert(this.$i18n.t('fatalError.errorAppForm'));

					this.submitting = false;

					return;

				}

			}

			// if no errors came up

			this.saveLoading(true);

			// A regex is used to extract the number in the current step path
			// it was supposed to only increment but it has many exceptions

			let match = this.$route.name.match(/^CoBorrowerStep(.*?)$/);
			let num = match[1];

			switch (num) {

				case '3':

					return this.$router.push({
						name: 'WaitingSubmission',
						params: {
							applicationId: this.applicationId
						}
					});

				// the num should increment to the next step
				default:
					num = parseInt(num, 10);
					num++;
					break;

			}

			return this.$router.push({
				name: `CoBorrowerStep${num}`,
				params: {
					applicationId: this.applicationId
				}
			});

		}

	},

	setDefaults(vModels) {

		this.setDefaultValues(vModels, this.coBorrower.data);

	}

};
