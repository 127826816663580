<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="1" :total="4" label="ListAssets"/>

		<mdb-card class="under-nav">

			<mdb-container>

				<mdb-card-body>

					<div class="form-group mt-4">

						<button
							size="md"
							@click="goBack({name: 'AddAsset', params: $route.params})"
							class="small bg-white border-0 text-uppercase text-info strong add-another"
						><i class="fa fa-plus-circle pr-2"></i>{{ $t('assets.addAnother') }}</button>

					</div>

					<form-field
						v-model="vModels.program"
						name="program"
						type="btn-group"
						bootstrap
						class="small"
					>

						<div
							v-for="asset in assets"
							:key="asset.type"
							type=""
							:value="asset.type"
							color="primary"
							:active="asset === vModels.asset"
							class="mr-sm-4 mb-3 card col-md-24 col-lg-7 p-0"
						>
							<div class="text-left text-white primary strong bg-primary rounded-top pr-1 py-2 pl-3">
								<h4 class="float-left text-white mt-2">Assets</h4>
								<div class="float-right">

									<button
										@click="goBack({name: 'EditAsset', params: { applicationId: applicationId, assetId: asset.assetId }})"
										class="rounded-circle bg-light text-info py-1 m-1 border-0"
									>
										<li class="fas fa-sm fa-pen p-1"></li>
									</button>

									<button
										class="rounded-circle bg-light text-danger py-1 m-1 border-0 mr-2"
										@click="confirmEventAndDelete(asset.assetId)"
									>
										<li class="fas fa-trash p-1"></li>
									</button>
									
								</div>
							</div>
							<div class="white rounded-bottom">

								<div class="px-3 py-2 pl-3 ">
									<div class="border-bottom">
										<span class="text-info text-left">Asset Type</span><span class="float-right">{{ asset.assetType }}</span>
									</div>
								</div>

								<div class="px-3 py-2 pl-3">
									<div class="border-bottom">
										<span class="text-info text-left">Asset Sub Type</span><span class="float-right">{{ asset.assetSubType }}</span>
									</div>
								</div>
								<div class="px-3 py-2 pl-3">
									<div class="border-bottom">
										<span class="text-info text-left">Description</span><span class="float-right">{{ asset.name }}</span>
									</div>
								</div>
								<div class="px-3 py-2 pl-3">
									<div>
										<span class="text-info text-left">Retail Cost</span><span class="float-right">{{ asset.retailCost }}</span>
									</div>
								</div>

							</div>
						</div>

					</form-field>

				</mdb-card-body>

				<div class="form-group button-row">

					<submit-btn
						type="submit"
						v-if="assets"
						@click="$router.push({name: 'FinancingProgram', params: $route.params})"
						>{{ $t('forms.next') }}</submit-btn>

				</div>

			</mdb-container>

		</mdb-card>

	</form>

</template>

<script>

	import ApplicationMixin from '../ApplicationForms/Application/ApplicationMixin';
	import FormsConfirmationMixin from '../ApplicationForms/FormsConfirmationMixin';
	import { getAssets, deleteAsset } from '../../../api';
	import { mapState, mapActions } from 'vuex';

	export default {

		mixins: [
			ApplicationMixin,
			FormsConfirmationMixin
		],

		data() {

			return {

				assets: [],
				vModels: {

				}

			};

		},

		watch: {

			application: {
				async handler(val) {

					this.saveLoading(true);

					if (!val || !val.data || !val.data.id) {

						return;

					}

					this.applicationId = val.data.id;

					console.log(this.applicationId);

					this.assets = null;

					this.assets = await this.refreshAssets();

					console.log('this.assets', this.assets);
					
					this.saveLoading(false);

				},
				deep: true,
				immediate: true
			},

			applicationId: {
				handler(val) {

					this.saveLoading(true);

					this.assets = null;

					this.assets = this.refreshAssets();

					this.saveLoading(false);

				}
			}

		},

		mounted() {

			this.assets = null;

			// this.assets = this.getAssets();

			// this.assets = getAssets(this.applicationId);

			// console.log('this.assets', this.assets);

		},

		methods: {

			
			...mapActions([
				'confirm'
			]),

			onSubmit(e) {

				console.debug(
					'[onSubmit] event:', e,
					'\nvModels:', JSON.stringify(this.vModels, null, 2)
				);

			},

			async confirmEventAndDelete(assetId) {

				await this.removeAlerts();

				this.saveLoading(true);

				this.confirmAlert = await this.confirm({
					message: this.$i18n.t('assets.confirmDelete'),
					buttons: [
						{text: this.$i18n.t('common.yes'), action: () => this.deleteAssetHelper(assetId), bold: false},
						{text: this.$i18n.t('common.no'), action: (toast) => this.$snotify.remove(toast.id)}
					]
				});

				this.confirmAlert.on('hidden', () => {

					this.confirmAlert = null;
					// this.$set(this.spinning, key, false);

				});

				this.saveLoading(false);

			},

			async refreshAssets() {

				this.saveLoading(true);

				let assets = await getAssets(this.applicationId);

				console.log(assets);

				let total = 0;

				if (assets) {
					
					Object.keys(assets).forEach((key, item) => {
						console.log(assets[key].retailCost);
						total += Number(assets[key].retailCost);
					});
					
				} else {
					this.assets = null;
				}

				this.assetTotal = total;
				
				this.assets = assets;

				this.saveLoading(false);

				return assets;

			},

			async deleteAssetHelper(assetId) {

				this.saveLoading(true);

				await this.removeAlerts();

				await deleteAsset(this.applicationId, assetId);

				await this.refreshAssets();

				console.log(assetId);

				this.saveLoading(false);

			}

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ .btn-group {
		display: flex;
		flex-wrap: wrap;

		.btn {
			border-radius: $btn-border-radius !important;
			width: 30%;
			margin: 1rem 1%;

			span {
				display: block;
			}

			&.btn-primary {
				&.active, &:active {
					background-color: $info !important;
				}
			}

		}

	}

	.add-another {
		&:hover {
			opacity: 0.5;
		}
	}

</style>
