import axios from 'axios';

export default {

	SAVE_AUTH_USER: (state, payload) => {

		state.authUser = payload;

	},

	SAVE_AUTH_USER_VENDOR: (state, payload) => {

		state.authUserVendor = payload;

	},

	SAVE_AUTH_USER_TOKEN: (state, payload) => {

		state.authUserToken = payload;

		if (payload) {

			axios.defaults.headers.common.Authorization = payload;

		} else {

			delete axios.defaults.headers.common.Authorization;

		}

	},

	SET_AUTH_USER_TOKEN_LAST_CHECK: (state, payload) => {

		state.authUserTokenLastCheck = payload ? Date.now() : null;

	}

};
