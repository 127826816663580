<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="3.2" :total="4" />

		<mdb-container>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.currentAddress') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<template v-for="(fieldData, fieldName) in getForm('3.2')">

						<form-field
							v-bind="checkForOptions(fieldName, fieldData)"
							v-model="vModels[fieldName]"
							:name="fieldName"
							:label="getLabel(fieldName)"
							:error="errors[fieldName]"
							:locale="locale"
							:mask="getMask(fieldName, fieldData)"
							:enable-only="enableOnly[fieldName]"
							bootstrap
							@change="onChange"
						>

							<template
								v-if="fieldName === 'row'"
								v-slot:fields
							>

								<form-field
									v-for="(childData, childName) in fieldData.fields"
									:key="fieldName + '-' + childName"
									v-bind="checkForOptions(childName, childData)"
									v-model="vModels[childName]"
									:name="childName"
									:label="getLabel(childName)"
									:error="errors[childName]"
									:locale="locale"
									:mask="getMask(childName, childData)"
									:enable-only="enableOnly[childName]"
									bootstrap
									@change="onChange"
								></form-field>

							</template>

						</form-field>

					</template>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					@click="goBack({name: 'ApplicationStep3.1', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from './ApplicationMixin';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				vModels: {
					country: null,
					address1: '',
					address2: '',
					province: null,
					city: '',
					postal: '',
					titleOwner: null,
					yearsAtCurHouse: '',
					note: ''
				},
				optionals: ['address2', 'yearsAtCurHouse']
			};

		}

	};

</script>

<style lang="scss" scoped>

</style>
