import { mapActions, mapState } from 'vuex';
import { scan } from '../../../api';
import * as ScanditSDK from 'scandit-sdk';

export default {

	data() {

		return {
			picker: null,
			result: null,
			scanning: false,
			showContinue: false,
			showScanner: false,
			// submitting: false, // already in BaseMixin
		};

	},

	beforeDestroy() {

		this.destroyScanning();

	},

	computed: {

		isScanPage() {

			return true;

		},

		scanBtnText() {

			if (this.showContinue) {

				return this.$i18n.t('newApplication.continueScanning');

			} else if (this.scanning && this.showScanner) {

				return this.$i18n.t('newApplication.pauseScanning');

			}

			return this.$i18n.t('newApplication.scanNow');

		}

	},

	methods: {

		...mapActions([
			'showErrorAlert'
		]),

		onClickScanBtn() {

			if (this.showContinue) {

				return this.continueScanning();

			} else if (this.scanning && this.showScanner) {

				return this.pauseScanning();

			}


			this.scanNow();

		},

		async scanNow() {

			// this.parseSpecial(''); return;

			try {

				this.scanning = true;
				this.showScanner = true;

				if (this.picker) {

					return;

				}

				let currentUrl = window.location.host;

				let scanKey = process.env.VUE_APP_SCANDIT; // keep the test key for now.

				try {

					// TODO: simplify
					if (currentUrl.indexOf('www.ueiapp.com') > -1) {

						scanKey = process.env.VUE_APP_SCANDIT_WWW_COM;

					} else if (currentUrl.indexOf('ueiapp.com') > -1) {

						scanKey = process.env.VUE_APP_SCANDIT_COM;

					} else if (currentUrl.indexOf('www.ueiapp.ca') > -1) {

						scanKey = process.env.VUE_APP_SCANDIT_WWW_CA;

					} else if (currentUrl.indexOf('ueiapp.ca') > -1) {

						scanKey = process.env.VUE_APP_SCANDIT_CA;

					} else {

						scanKey = process.env.VUE_APP_SCANDIT;

					}

				} catch (error) {
					// something went wrong - revert to working test key
					scanKey = process.env.VUE_APP_SCANDIT;
				}

				// TODO: change to a local build dir
				ScanditSDK.configure(scanKey, {
					engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@4.x/build'
				});

				this.picker = await ScanditSDK.BarcodePicker.create(this.$refs.scanner, {
					playSoundOnScan: true,
					vibrateOnScan: true
				}).catch(error => {

					if (error.name === 'UnsupportedBrowserError') {

						// console.log(JSON.stringify(error, null, 2));

						this.showErrorAlert(
							this.$i18n.t('fatalError.scanUnsupportedBrowserError')
						);

					} else {

						this.showErrorAlert(error.toString());

					}

				});

				if (!this.picker) {

					this.scanning = false;
					this.showScanner = false;
					return;

				}

				// Create the settings object to be applied to the scanner
				const scanSettings = new ScanditSDK.ScanSettings({
					enabledSymbologies: [
						ScanditSDK.Barcode.Symbology.PDF417
					],
					codeDuplicateFilter: 0
				});

				this.picker.applyScanSettings(scanSettings);

				// If a barcode is scanned, show it to the user and pause scanning
				// (scanning is resumed when the user clicks "Continue Scanning")
				this.picker.on('scan', this.onScan);
				this.picker.on('scanError', this.onScanError);

			} catch (error) {

				this.scanning = false;
				this.showScanner = false;

				switch (error.name) {

					case 'PermissionDeniedError':
						this.showErrorAlert(
							this.$i18n.t('fatalError.scanPermissionDeniedError')
						);
						break;

					default:
						this.showErrorAlert(
							this.$i18n.t('fatalError.scanError', {
								errorName: error.message ? '[' + error.message + ']' : ''
							})
						);
						break;

				}

			}

		},

		pauseScanning() {

			if (this.picker) {

				// Pause scanning
				this.picker.pauseScanning();

				this.showContinue = true;

			}

			this.showScanner = false;
			this.scanning = false;

		},

		destroyScanning() {

			if (this.picker) {

				// Stop scanning
				this.picker.destroy(true);

			}

		},

		continueScanning() {

			this.showScanner = true;
			this.scanning = true;

			if (this.picker) {

				this.showContinue = false;

				// Resume scanning
				this.picker.resumeScanning();

			}

		},

		onScan(scanResult) {

			this.pauseScanning();

			let result = scanResult.barcodes.reduce((string, barcode) => {

				return string + `${ ScanditSDK.Barcode.Symbology.toHumanizedName(barcode.symbology) }: ${ barcode.data }<br>`;

			}, '');

			this.result = result;

			if (result.substring(0, 11) == 'PDF417: %BC') {

				this.parseSpecial(result);

			} else {

				this.parseData(result);

			}

		},

		onScanError(error) {

			this.pauseScanning();

			console.debug('onScanError', error.message, JSON.stringify(error, null, 2));

			this.showErrorAlert('Scan Error:' + error.message);

		},

		convertCode(abbr, str) {

			let startPos = str.indexOf(abbr);

			if (startPos === -1) {

				return;

			}

			let newStr = str.substring(startPos + abbr.length);
			let endPos = newStr.indexOf("\n");

			if (endPos === -1) {

				return;

			}

			let endStr = newStr.substring(0, endPos);

			if (['DAC', 'DBP', 'DCT'].includes(abbr)) {

				let commaPos = endStr.indexOf(",");
				if (commaPos !== -1) {

					endStr = endStr.substring(0, commaPos);

				}

			}

			return endStr.replace(',', ' ').trim();

		},

		parseSpecial(data) {

			if (!data) {

				this.showErrorAlert('Scan Error');
				return;
			
			}

			let lastName = data.substring(data.indexOf('^') + 1, data.indexOf(','));
			let firstName = data.substring(data.indexOf('$') + 1, data.indexOf('^', data.indexOf(lastName)));
			let address = data.substring(data.indexOf('^', data.indexOf(firstName)) + 1, data.indexOf('$', data.indexOf(firstName)));
			let city = data.substring(
				data.indexOf('$', data.indexOf(address) + address.length) + 1,
				data.indexOf(' ', data.indexOf(address) + address.length)
			);
			let postal = data.substring(data.indexOf('BC  ') + 4, data.indexOf('BC  ') + 11);
			let dob = data.substring(
				data.indexOf('=') + 1,
				data.indexOf('=', data.indexOf('=') + 1)
			);

			// remove middle name
			if (firstName.indexOf(' ') > 1) {

				firstName = firstName.substring(0, firstName.indexOf(' '));
			
			}
			if (firstName.indexOf('-') > 1) {

				firstName = firstName.substring(0, firstName.indexOf('-'));
			
			}

			let info = {
				firstName: firstName,
				lastName: lastName,
				dob: dob.substring(2,4) + '/' + dob.substring(0,2) + '/' + dob.substring(4,8),
				address1: address,
				postal: postal,
				city: city,
				province: 'BC',
				country: 'CA'
			};

			// console.log((info));

			Object.keys(info).forEach(key => {

				this.$set(this.vModels, key, info[key]);
			
			});

			this.onSubmit();

			return info;

		},

		parseData(data) {

			if (!data) {

				this.showErrorAlert('Scan Error');

				return;

			}

			let fields = {
				firstName: ['DAC', 'DBP', 'DCT'],
				lastName: ['DAB', 'DBO', 'DCS'],
				dob: 'DBB',
				address1: 'DAG',
				postal: 'DAK',
				city: 'DAI',
				province: 'DAJ',
				country: 'DCG'
			};

			let info = {};

			Object.keys(fields).forEach(key => {

				if (Array.isArray(fields[key])) {

					fields[key].forEach(abbr => {

						let val = this.convertCode(abbr, data);

						if (val) {

							info[key] = val;

						}

					});

				} else {

					let val = this.convertCode(fields[key], data);

					if (val) {

						info[key] = val;

					}

				}

				if (info[key]) {

					switch (key) {

						case 'dob':
							info[key] = info[key].substring(4, 6) + '/' + info[key].substring(6, 8) + '/' + info[key].substring(0, 4);
							break;

						case 'country':
							info[key] = (info[key] === 'CAN') ? 'CA' : info[key];
							break;

					}

					this.$set(this.vModels, key, info[key]);

				}

			});

			this.onSubmit();

			return info;

		}

	}


};
