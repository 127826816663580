<template>

	<mdb-container class="mt-5" fluid>

		<datatable
			:data="databoxes"
			:loading="isLoading"
			:locale="locale"
			display-style="list"
			:order="[1, 'desc']"
			ref="databoxes"
		>

			<template v-slot:loading>
				<div style="width: 100%; height: 150px; position: relative">
					<loading :active="true" :is-full-page="false" :width="50" :height="50" background-color="transparent"></loading>
				</div>
			</template>

			<template v-slot:toolsLeft>

				<btn size="sm" color="info" class="ml-3" @click="syncApps" :loading="syncing" :spinner-size="17">
					<icon name="sync" size="lg"></icon>
				</btn>

				<transition>
					<span v-if="syncingMsg" class="label label-success">Sync Finished</span>
				</transition>

			</template>

			<template v-slot:column-status="{ item, itemKey }">
				<span :class="tagClass(item)">
					{{ titleCase(item.display) }}
				</span>
			</template>

			<template v-slot:column-actions-column="{ item, itemKey }">

				<ul class="list-unstyled d-block w-100 p-0 mb-0">

					<spinner v-if="actions[item.id] && !actions[item.id].resolved" :height="30" :width="30" color="#008093" class="d-none"></spinner>

					<template v-else v-for="(btn, btnKey) in actions[item.id].btns">

						<btn
							type="button"
							:key="itemKey + '-' + btnKey + '-btn'"
							:loading="spinning[itemKey + '-' + btnKey]"
							:spinner-size="16"
							:spinner-absolute="true"
							class="btn-block"
							@click="confirmEventAndGo(btn, btnKey, itemKey + '-' + btnKey)"
							v-bind="getBtnAttrs(btn)"
						>{{ getBtnLabel(btn, btnKey) }}</btn>

					</template>

					<btn v-if="gotPermissionsFor.indexOf(item.id) < 0" :id="'get_perm_' + item.id" size="sm" color="info" :class="'ml-3- btn-block mt-3 ' + item.id" @click="retryPermissions(item.id, item.status)">
						<icon name="sync" size="lg" class="mr-2"></icon>
						<span v-if="!(gettingPermissionsFor.indexOf(item.id) > -1)"> Get Actions Available</span>
						<span v-if="gettingPermissionsFor.indexOf(item.id) > -1"><br><br>Requesting</span>
					</btn>

				</ul>

			</template>

		</datatable>

	</mdb-container>

</template>

<script>

	import Spinner from 'vue-loading-overlay/src/loaders/spinner';
	import Datatable from '../../elements/components/Datatable';
	import moment from 'moment';
	import { mapState, mapActions } from 'vuex';
	import { pascalCase, kebabCase, titleCase } from 'change-case';
	import { search, getPermissions, sync, cancelPending } from '../../api';
	import { formatCurrency } from '../../elements/globalDirectives/CurrencyLocale';
	import axios from 'axios';

	let source = axios.CancelToken.source();

	export default {

		components: {
			Spinner,
			Datatable
		},

		data() {

			return {

				syncing: false,
				syncingMsg: false,
				// showGetPermissionsBtn: true,
				// stopAllRequests: false,

				applications: [],

				gotPermissionsFor: [],
				gettingPermissionsFor: [],

				databoxes: {
					rows: [],
					columns: []
				},

				actions: {},
				spinning: {},

				isLoading: true,

				errorAlert: null,
				confirmAlert: null

			};

		},

		computed: {

			...mapState({
				locale: state => state.app.locale,
				authUser: state => state.auth.authUser
			}),

			applicationFieldNames() {

				let names = [
					'id',
					'created',
					'lastName',
					'firstName',
					'fullName',
					'status',
					'edgeId',
					'amount',
					'amountApproved',
					'rentalAmount',
					'primaryPhone',
					'address1',
					'city',
					'actionsColumn'
				];

				if (!['all', 'approved', 'kamsel', 'funded'].includes(this.$route.params.status)) {

					['amountApproved', 'rentalAmount'].forEach(k => {

						let pos = names.indexOf(k);
						names.splice(pos, 1);

					});

				}

				// ticket 1854
				if (['kamsel'].includes(this.$route.params.status)) {

					['rentalAmount'].forEach(k => {

						let pos = names.indexOf(k);
						names.splice(pos, 1);

					});

				}

				if (['all'].includes(this.$route.params.status)) {

					['firstName', 'lastName'].forEach(k => {

						let pos = names.indexOf(k);
						names.splice(pos, 1);

					});

				} else {

					let pos = names.indexOf('fullName');
					names.splice(pos, 1);

				}

				if (this.$route.params.status === 'incomplete') {

					let pos = names.indexOf('edgeId');
					names.splice(pos, 1);

					pos = names.indexOf('status');
					names.splice(pos, 1);

				}

				return names;

			}

		},

		watch: {

			applications: {
				handler(val) {

					this.synchronize(val);

				},
				immediate: true
			}

		},

		created() {

			console.log('this', this);

			this.setName(this.$route);

			this.loadRows();

		},

		mounted() {

			this.loadSearch(this.$route);

		},

		beforeDestroy() {

			this.databoxes.columns = {};
			this.databoxes.rows = {};
			this.removeAlert(this.errorAlert);
			this.removeAlert(this.confirmAlert);

		},

		methods: {

			...mapActions([
				'saveTitle',
				'showErrorAlert',
				'removeAlert',
				'removeAlerts',
				'confirm'
			]),

			titleCase: s => titleCase(s),
			isPromise: (p) => p && Object.prototype.toString.call(p) === "[object Promise]",

			setName(to) {

				this.saveTitle(this.$i18n.t('titles.' + pascalCase(to.params.status)));

			},

			tagClass(item) {

				return 'status-tag status-tag-' + kebabCase(item.value);

			},

			async confirmEventAndGo(btn, btnKey, key) {

				await this.removeAlerts();
				this.$set(this.$data, 'spinning', {});

				if (btn.confirm === false) {

					return this.$router.push(btn.to);

				}

				this.$set(this.spinning, key, true);

				let label = this.getBtnLabel(btn, btnKey);

				this.confirmAlert = await this.confirm({
					message: this.$i18n.t('applications.confirmEvent', {label}),
					buttons: [
						{text: this.$i18n.t('common.yes'), action: () => this.$router.push(btn.to), bold: false},
						{text: this.$i18n.t('common.no'), action: (toast) => this.$snotify.remove(toast.id)}
					]
				});

				this.confirmAlert.on('hidden', () => {

					this.confirmAlert = null;
					this.$set(this.spinning, key, false);

				});

			},

			/*addParamsToLocation(params) {

				history.pushState(
					{},
					null,
					this.$route.path +
					'?' +
					Object.keys(params)
						.map(key => {
							return (
								encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
							)
						})
						.join('&')
				)

			},*/

			loadSearch(to) {

				if (to.query && to.query.q) {

					if (this.$refs.databoxes) {

						this.$set(this.$refs.databoxes, 'searchModel', to.query.q);
						this.$refs.databoxes.updateSearch(to.query.q);

					}

				}

			},

			async loadRows() {

				this.applicationFieldNames.forEach(fieldName => {

					this.databoxes.columns.push({
						label: this.$i18n.t('applications.' + fieldName),
						field: fieldName,
						sort: (fieldName === 'actionsColumn') ? false : 'asc',
						hidden: (fieldName === 'id')
					});

				});

				await this.getApplications();

			},

			async getApplications() {

				let status = pascalCase(this.$route.params.status);

				let apps = await search(status).catch(async error => {

					this.errorAlert = await this.showErrorAlert(this.$i18n.t('fatalError.fetchingContracts'));

				});

				this.$set(this.$data, 'applications', apps);

				this.isLoading = false;

			},

			fetch(id, arr) {

				arr = this.databoxes.rows || arr;

				return arr.find(item => (item.id === id || (item.application && item.application.id === id)));

			},

			async syncApps() {

				this.syncing = true;

				let syncResult = await sync();

				this.syncingMsg = true;
				this.syncing = false;

				setTimeout(() => {

					this.syncingMsg = false;

				}, 3000);

			},
			
			async retryPermissions(id, status) {

				this.gettingPermissionsFor.push(id)

				this.syncing = true;

				let source = axios.CancelToken.source();

				await cancelPending(source);

				source = axios.CancelToken.source();

				let perms = await this.getPermissionBtns({
					application: {
						id: id,
						status
					},
					source: source
				});

				// this.stopAllRequests = true;

				this.syncing = false;
				// this.showGetPermissionsBtn = false;

				this.synchronize()

			},

			async wait(time) {
				await new Promise(resolve => setTimeout(resolve, time));
			},

			synchronize() {

				console.log('synchronize');

				if (!this.applications) {
					this.databoxes.rows.length = 0;
					return;
				}

				this.databoxes.rows.forEach(item => {

					let found = this.fetch(item.id, this.applications);

					if (!found) {

						// remove from results because gone
						this.databoxes.rows.splice(this.databoxes.rows.indexOf(item), 1);

					}

				});

				this.applications.forEach(item => {

					if (!this.fetch(item.application.id)) {

						// add to results because new

						let obj = {
							id: item.application.id
						};

						this.applicationFieldNames.forEach(name => {

							if (['fullName'].includes(name)) {

								let fullName = (item.application['firstName'] || '') + ' ' + (item.application['lastName'] || '');

								obj[name] = {
									display: fullName != ' ' ? fullName : '-',
									value: item.application['lastName']
								};

							} else {

								obj[name] = {
									display: item.application[name] || '-',
									value: item.application[name]
								};

							}

							if (name === 'created' && obj[name] !== '-' && obj[name].value) {

								let dt = moment(obj.created.value);
								obj.created.value = parseInt(obj[name].value);

								obj.created.display = dt.format('MMM Do YYYY');
								obj.created.display += '<br /><span class="text-muted">' + dt.format('hh:mm a') + '</span>';

							} else if (name === 'address1' && item.application.address2) {

								if (obj.address1.display === '-') {

									obj.address1.display = '';
									obj.address1.value += item.application.address2;

								} else {

									obj.address1.display += '<br />';
									obj.address1.value += ' ' + item.application.address2;

								}

								obj.address1.display += item.application.address2;

							} else if (['amount', 'amountApproved', 'rentalAmount'].includes(name) && obj[name].value) {

								try {
									obj[name].value = parseFloat(obj[name].value);
									obj[name].display = formatCurrency(obj[name].value, this.locale);
								} catch (error) {
									
								}

							}

						});

						if (!this.actions[item.application.id]) {

							this.actions[item.application.id] = {}; //this.getPermissionBtns(item);

						}

						obj.actionsColumn = {
							display: null,
							value: null,
							id: item.application.id,
							status: item.application.status
						};

						this.databoxes.rows.push(obj);

						// end of "add to results because new"

					}

				});

			},

			async getPermissionBtns(item) {

				let id = item.application.id;
				let status = item.application.status;

				let obj = {
					resolved: true,
					btns: {}
				};

				if (status === 'Incomplete') {

					obj.btns = {

						application: {
							color: 'info',
							to: {
								name: 'ApplicationStep1',
								params: {
									applicationId: id
								}
							},
							class: 'my-1',
							confirm: false
						},

						coBorrower: {
							color: 'info',
							to: {
								name: 'CoBorrowerStep0',
								params: {
									applicationId: id
								}
							},
							class: 'my-1',
							confirm: false
						},

						delete: {
							color: 'info',
							to: {
								name: 'ApplicationEvent',
								params: {
									applicationId: id,
									event: 'delete'
								}
							},
							class: 'my-1',
							confirm: true
						}

					};

					// return obj;

					// if ((!item.application.hasCoApplicant || item.application.hasCoApplicant === 'No') && Object.keys(item).length === 1) {

					// 	obj.btns.coBorrower.class += ' not-active-btn';

					// }

				} else if (
					['Signatures Received'].includes(status)
				) {

					if (this.checkWhitelist()) {

						let addDocs = {
							en: 'Add Documents and Submit',
							fr: 'Add Documents and Submit',
						};
	
						obj.btns.addDocs = {
							color: 'info',
							label: addDocs,
							to: {
								name: 'UploadFilesStep1',
								params: {
									applicationId: id
								}
							},
							class: 'my-1',
							confirm: false
						};
						
					}

				} else if (
					['Waiting for Signature'].includes(status)
				) {

					if (this.checkWhitelist()) {

						let addDocs = {
							en: 'Verify / Receive Signature',
							fr: 'Verify / Receive Signature',
						};

						obj.btns.addDocs = {
							color: 'info',
							label: addDocs,
							to: {
								name: 'Sign',
								params: {
									applicationId: id
								}
							},
							class: 'my-1',
							confirm: false
						};

					}

				} else if (['Approved', 'Approved for Lesser Amount'].includes(status)) {
						
						console.log('authUser', this.authUser);
					
					if (this.checkWhitelist()) {

						let initiatePhase2Labels = {
							en: 'Generate Contract',
							fr: 'Generate Contract',
						};
	
						obj.btns.initiatePhase2 = {
							color: 'info',
							label: initiatePhase2Labels,
							to: {
								name: 'Assets',
								params: {
									applicationId: id
								}
							},
							class: 'my-1',
							confirm: false
						};

					}
					

					// let financingProgramsLabels = {
					// 	en: 'Financing Program',
					// 	fr: 'Financing Program',
					// };

					// obj.btns.financingProgramsLabels = {
					// 	color: 'info',
					// 	label: financingProgramsLabels,
					// 	to: {
					// 		name: 'FinancingProgram',
					// 		params: {
					// 			applicationId: id
					// 		}
					// 	},
					// 	class: 'my-1',
					// 	confirm: false
					// };

					// let uploadFilesLabels = {
					// 	en: 'Upload Files',
					// 	fr: 'Upload Files',
					// };

					// obj.btns.uploadFilesLabels = {
					// 	color: 'info',
					// 	label: uploadFilesLabels,
					// 	to: {
					// 		name: 'UploadFilesStep1',
					// 		params: {
					// 			applicationId: id
					// 		}
					// 	},
					// 	class: 'my-1',
					// 	confirm: false
					// };

					// let signLabels = {
					// 	en: 'Sign Documents',
					// 	fr: 'Sign Documents',
					// };

					// obj.btns.signLabels = {
					// 	color: 'info',
					// 	label: signLabels,
					// 	to: {
					// 		name: 'HelloSign',
					// 		params: {
					// 			applicationId: id
					// 		}
					// 	},
					// 	class: 'my-1',
					// 	confirm: false
					// };
					

					let label = {
						en: 'Re-Submit with Updated Info',
						fr: 'Re-Submit with Updated Info',
					};

					obj.btns.evtResubmittoCredit = {
						color: 'info',
						//label: this.$t('applications.evtResubmittoCredit'),//label,
						to: {
							name: 'ApplicationStep1',
							params: {
								applicationId: id
							}
						},
						class: 'my-1',
						confirm: false
					};

					let newLabel = {
						en: 'Re-Submit with Co-Applicant',
						fr: 'Re-Submit with Co-Applicant',
					};

					obj.btns.evtResubmittoCreditWithCoApplicant = {
						color: 'info',
						//label: this.$t('applications.evtResubmittoCreditWithCoApplicant'),//newLabel,
						to: {
							name: 'CoBorrowerStep0',
							params: {
								applicationId: id
							}
						},
						class: 'my-1',
						confirm: false
					};

				}

				

				let isPermissionsSuccessful = false;
				let count = 0;

				while (
					!isPermissionsSuccessful
					// && !this.stopAllRequests
					&& status != 'Incomplete'
					// && !this.isGettingSuccessful
				) {

					let res = false;

					console.log('getting perms');
					count++;

					if (count > 1) {

						console.log('before');
						await this.wait(3000);
						console.log('after');

					}

					try {

						if (item.source) {
							const source = item.source;
						} else {
							console.log('getPermissionBtns: creating new source');
							const source = await axios.CancelToken.source();
						}
						res = await getPermissions(id, source);

					} catch (error) {
						console.log('error occured');
						continue;
					}

					isPermissionsSuccessful = true;

					if (res && res.permissions) {

						Object.keys(res.permissions).forEach(eventName => {

							if (eventName === 'evtResubmittoCredit') {

								let label = {
									en: 'Re-Submit with Updated Info',
									fr: 'Re-Submit with Updated Info',
								};

								obj.btns[eventName] = {
									color: 'info',
									//label: label,
									to: {
										name: 'ApplicationStep1',
										params: {
											applicationId: id
										}
									},
									class: 'my-1',
									confirm: false
								};

								let newLabel = {
									en: 'Re-Submit with Co-Applicant',
									fr: 'Re-Submit with Co-Applicant',
								};

								obj.btns.evtResubmittoCreditWithCoApplicant = {
									color: 'info',
									//label: newLabel,
									to: {
										name: 'CoBorrowerStep0',
										params: {
											applicationId: id
										}
									},
									class: 'my-1',
									confirm: false
								};

								return;

							}

							obj.btns[eventName] = {
								color: 'info',
								label: res.permissions[eventName][this.locale] ? res.permissions[eventName] : null,
								to: {
									name: 'ApplicationEvent',
									params: {
										applicationId: id,
										event: kebabCase(eventName)
									}
								},
								class: 'my-1',
								confirm: true
							};

						});

					}

				}

			console.log('item', item);
			console.log('status', status);

			if (status === 'Incomplete') {

				obj.btns = {

					application: {
						color: 'info',
						to: {
							name: 'ApplicationStep1',
							params: {
								applicationId: id
							}
						},
						class: 'my-1',
						confirm: false
					},

					coBorrower: {
						color: 'info',
						to: {
							name: 'CoBorrowerStep0',
							params: {
								applicationId: id
							}
						},
						class: 'my-1',
						confirm: false
					},

					delete: {
						color: 'info',
						to: {
							name: 'ApplicationEvent',
							params: {
								applicationId: id,
								event: 'delete'
							}
						},
						class: 'my-1',
						confirm: true
					}

				};

				// return obj;

				// if ((!item.application.hasCoApplicant || item.application.hasCoApplicant === 'No') && Object.keys(item).length === 1) {

				// 	obj.btns.coBorrower.class += ' not-active-btn';

				// }

			}

				
				
			
				this.$set(this.actions, id, obj);

				setTimeout(() => {

					console.log('id', id);
					// const button = document.getElementById('get_perm_' + id)
					// const button = document.getElementsByClassName("'" + id + "'")

					// console.log(button);

					// if (button) {
						// button[0].style.display = 'none';
					// }

					// if (this.gotPermissionsFor.indexOf(id) ) {
					// 	console.log('yes');
					// }

					this.gotPermissionsFor.push(id)

					console.log('this.gotPermissionsFor', this.gotPermissionsFor);

					this.$set(this.actions, id, obj);
					this.$refs.databoxes && this.$refs.databoxes.$forceUpdate();

				});

				return obj;

			},

			getBtnAttrs(attrs) {

				attrs = Object.assign({size: 'sm'}, attrs);

				delete attrs.to;
				delete attrs.label;

				return attrs;

			},

			getBtnLabel(btn, btnKey) {

				if (btn.label) {

					if (typeof btn.label === 'string') {

						return btn.label;

					}

					return btn.label[this.locale];

				}

				return this.$i18n.t('applications.' + btnKey + 'Btn');

			},

			checkWhitelist() {

				if (

					(
						this.authUser.vendorInternalOrExternal
						&& this.authUser.vendorInternalOrExternal == 'External Vendor'
					)

				) {

					return true;

				}

				return false;
				
			}

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ {

		.label {
			text-transform: uppercase;
			font-weight: bold;
			font-size: 10px;
			padding: 3px 8px;
			border-radius: 10px;
			user-select: none;
			cursor: default;

			&.label-success {
				background: $success-color;
				color: #fff;
			}
		}

		.databoxes-length label {
			padding-top: 2px;
		}

		.hidden {
			opacity: 0;
			position: absolute;
			top: -1000;
			left: -1000;
			z-index: -1;
		}

		.databox-grid {

			.table-col-actions-column {
				height: 12rem;

				.btn {
					display: block;
					width: 100%;
					padding-left: 0.5rem;
					padding-right: 0.5rem;

					& + .btn {
						margin-top: 1rem !important;
					}
				}
			}

		}

		.status-tag {
			background: $light;
			border-radius: 4px;
			padding: 0.05em 0.5em;
			font-size: 12px;
			display: inline-flex;
			align-content: center;
			align-items: center;
			line-height: 1em;
			height: 2em;
			white-space: nowrap;

			&:before {
				content: "•";
				font-size: 20px;
				margin-right: 0.25em;
			}

			&.status-tag-incomplete {
				$color0: $gray-300;
				background: $color0;
				color: darken($color0, 60%);
			}

			&.status-tag-approved {
				$color1: #bff99f;
				background: $color1;
				color: darken($color1, 65%);
			}

			&.status-tag-funded {
				$color2: #b3e2ff;
				background: $color2;
				color: darken($color2, 60%);
			}

			&.status-tag-not-approved {
				$color3: #ffc4ba;
				background: $color3;
				color: darken($color3, 50%);
			}

			&.status-tag-cancelled {
				$color4: $gray-500;
				background: $color4;
				color: darken($color4, 50%);
			}

			&.status-tag-pending, &.status-tag-bureau-not-found {
				$color5: #fff2d1;
				background: $color5;
				color: darken($color5, 60%);
			}

			&.status-tag-submitted-to-kamsel {
				$color6: #f3ddff;
				background: $color6;
				color: darken($color6, 60%);
			}

		}

	}

</style>
