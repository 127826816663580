<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="3.3" :total="4" />

		<mdb-container>

			<template v-for="(fieldData, fieldName) in getForm('3.3pre')">

				<form-field
					v-bind="checkForOptions(fieldName, fieldData)"
					v-model="vModels[fieldName]"
					:name="fieldName"
					:label="getLabel(fieldName)"
					:error="errors[fieldName]"
					:locale="locale"
					bootstrap
					@change="onChange"
				></form-field>

			</template>

			<hr style="border: 1px lightgrey dashed; margin: 2rem 0;" />

			<height-transition>
				<mdb-card v-if="applicationSections.curAddressLess">
					<mdb-card-body>

						<template v-for="(fieldData, fieldName) in getForm('3.3')">

							<form-field
								v-bind="checkForOptions(fieldName, fieldData)"
								v-model="vModels[fieldName]"
								:name="fieldName"
								:label="getLabel(fieldName)"
								:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
								:error="errors[fieldName]"
								:locale="locale"
								:mask="getMask(fieldName, fieldData)"
								bootstrap
								@change="onChange"
							>

								<template
									v-if="fieldName === 'row'"
									v-slot:fields
								>

									<form-field
										v-for="(childData, childName) in fieldData.fields"
										:key="fieldName + '-' + childName"
										v-bind="checkForOptions(childName, childData)"
										v-model="vModels[childName]"
										:name="childName"
										:label="getLabel(childName)"
										:options="(options[childName]) ? options[childName] : childData.options"
										:error="errors[childName]"
										:locale="locale"
										:mask="getMask(childName, childData)"
										bootstrap
										@change="onChange"
									></form-field>

								</template>

							</form-field>

						</template>

					</mdb-card-body>
				</mdb-card>
			</height-transition>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					@click="goBack({name: 'ApplicationStep3.2', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	import ApplicationMixin from './ApplicationMixin';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				showSubSection: false,
				vModels: {
					curAddressLess: '',
					prevAddress1: '',
					prevAddress2: '',
					prevCity: '',
					prevProvince: null,
					prevPostal: ''
				},
				optionals: ['prevAddress2']
			};

		},

		watch: {

			'vModels.curAddressLess': {
				handler(val) {

					this.saveApplicationAction({
						data: {
							curAddressLess: val
						},
						errors: {}
					});

					this.saveApplicationSections({
						curAddressLess: (val === 'Yes')
					});

				}
			}

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ .form-group {

		&.label-like-h4 {

			& > label {
				font-size: $h4-font-size;
				font-weight: 700 !important;
				color: $dark;
			}

		}

	}

</style>
