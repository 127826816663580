<template>

	<mdb-container class="mt-5">

		<mdb-row>

			<mdb-col md="7" class="order-md-2">

				<mdb-row class="mb-4">
					<mdb-col md="24">

						<router-link tag="div" :to="{name: 'ApplicationStep1', params: {applicationId: 'new'}}">
							<btn tag="a" color="primary" size="xl"><icon name="add" type="uei-icon" class="mr-3 font-lg" />{{ $t('titles.NewApplication') }}</btn>
						</router-link>

					</mdb-col>
					<mdb-col md="24">

						<router-link tag="div" :to="{name: 'PaymentCalculator', params: {}}">
							<btn tag="a" color="success" size="xl">
								<i class="fa fa-calculator" style="font-size:25px;margin-right:10px;"></i> {{ $t('titles.PaymentCalculator') }}
							</btn>
						</router-link>

					</mdb-col>
				</mdb-row>

			</mdb-col>

			<mdb-col md="17" class="order-md-1">

				<mdb-card-group deck class="flex-wrap justify-content-between mr-lg-2" ref="summary">

					<template v-for="(widget, status) in widgets">

						<router-link
							:to="getUrl(status)"
							:key="'widget-' + status"
							class="flex-48 flex-xs-100 flex-lg-48 mb-4"
							ref="statusBox"
						>

							<mdb-card :text-color="widget.color" color="white" class="widget-card">
								<mdb-card-body v-match-heights="{el: ['.widget-card .card-body']}">

									<!--<h4 class="text-uppercase font-small mb-1" :class="'text-' + widget.color">{{ $t('dashboard.application') }}</h4>-->
									<p class="text-3rem mb-1">
										<template v-if="typeof widget.num === 'number'">{{ widget.num }}</template>
										<template v-else>-</template>
									</p>
									<p class="m-0">{{ $t('dashboard.' + status) }}</p>

								</mdb-card-body>
							</mdb-card>

						</router-link>

					</template>

				</mdb-card-group>

			</mdb-col>

		</mdb-row>

	</mdb-container>

</template>

<script>

	import { mapActions, mapState } from 'vuex';
	import { pascalCase, kebabCase } from 'change-case';
	import { summary } from '../../api';

	export default {

		data() {

			return {
				isLoading: true,
				errorAlert: null,
				widgets: {

					Incomplete: {
						key: 'Incomplete',
						color: 'primary',
						order: 1
					},

					All: {
						key: 'All',
						color: 'black',
						order: 2
					},

					Pending: {
						key: 'Pending',
						color: 'info',
						order: 3
					},

					Approved: {
						key: 'Approved',
						color: 'primary',
						order: 4
					},
					
					DocumentProgress: {
						key: 'DocumentProgress',
						color: 'primary',
						order: 5
					},

					NotApproved: {
						key: 'NotApproved',
						color: 'primary',
						order: 6
					},

					Kamsel: {
						key: 'Kamsel',
						color: 'secondary',
						order: 7
					},

					MissingDocuments: {
						key: 'MissingDocuments',
						color: 'danger',
						order: 8
					},

					Funded: {
						key: 'Funded',
						color: 'success',
						order: 9
					},

					CompleteBooking: {
						key: 'CompleteBooking',
						color: 'success',
						order: 10
					},

					Cancelled: {
						key: 'Cancelled',
						color: 'black',
						order: 11
					}

				}
			};

		},

		computed: {

			...mapState({
				applicationLists: state => state.application.applicationLists
			})

		},

		created() {

			this.getSummary();

		},

		beforeDestroy() {

			this.removeAlert(this.errorAlert);

		},

		methods: {

			...mapActions([
				'showErrorAlert',
				'removeAlert',
				'saveApplicationListItem'
			]),

			getUrl(val) {

				return {
					name: 'ApplicationList',
					params: {
						status: kebabCase(val)
					}
				};

			},

			async getSummary() {

				if (this.applicationLists && Object.keys(this.applicationLists).length) {

					Object.keys(this.applicationLists).forEach(key => {

						this.$set(this.widgets, key, Object.assign({}, this.applicationLists[key]));

					});

				}

				let response = await summary().catch(async error => {

					this.errorAlert = await this.showErrorAlert(this.$i18n.t('fatalError.fetchingSummary'));

				});

				if (!response) {

					return;

				}

				Object.keys(response).forEach(name => {

					let key = pascalCase(name);

					if (!this.widgets[key]) {

						this.$set(this.widgets, key, {
							key,
							color: 'primary',
							order: 99
						});

					}

					this.$set(this.widgets[key], 'num', parseInt(response[name]));

					let label = 'dashboard.' + key;

					if (this.$i18n && this.$i18n.te(label)) {

						label = this.$i18n.t(label);

					}

					this.$set(this.widgets[key], 'label', label);

					this.saveApplicationListItem(this.widgets[key]);

				});

				this.isLoading = false;

			}

		}

	};

</script>

<style lang="scss" scoped>

	.card .card-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: flex-start;
		align-items: flex-start;

		@include media-breakpoint-down(xs) {

			p {
				font-size: 1em;
			}

		}

	}

</style>
