<template>

	<mdb-card class="under-nav">
		<mdb-container>
			<mdb-card-body class="px-sm-0">

				<div class="steps-form">
					<div class="steps-row">

						<div v-for="(step, index) in steps" :key="index" class="steps-step">

							<router-link
								v-if="compare(step.num) === 1"
								:to="getRoute(step.num)"
								type="button"
								class="btn btn-circle btn-white btn-outline-primary"
							>{{ step.num }}</router-link>

							<router-link
								v-else-if="compare(step.num) === -1"
								:to="getRoute(step.num)"
								type="button"
								class="btn btn-circle btn-info"
							><icon name="done" type="ma" /></router-link>

							<router-link
								v-else
								:to="getRoute(step.num)"
								type="button"
								class="btn btn-circle btn-info"
							>{{ step.num }}</router-link>

							<p>{{ step.label ? step.label : step.name }}</p>

						</div>

					</div>
				</div>

			</mdb-card-body>
		</mdb-container>
	</mdb-card>

</template>

<script>

	import { mapState } from 'vuex';
	// import UploadFilesStep1Vue from '../UploadFilesStep1.vue';

	let phase2 = [
		'DummyToKeepNumConvention',
		'Assets',
		'FinancingProgram',
		'Sign',
		'UploadFilesStep1'
	]

	let labels = {
		Assets: 'Assets',
		FinancingProgram: 'Program',
		Sign: 'Sign',
		UploadFilesStep1: 'Upload',
	}

	export default {

		props: {

			current: {
				type: [String, Number],
				default: 0
			},

			total: {
				type: Number,
				default: 2
			},

			scan: {
				type: Boolean,
				default: false
			},

			label: {
				type: String,
				default: ''
			}

		},

		data() {

			return {
				applicationId: null
			};

		},

		computed: {

			...mapState({
				application: state => state.application.info
			}),

			steps() {

				let steps = [];

				for (let i = this.scan ? 0 : 1; i <= this.total; i++) {

					steps.push({
						num: i,
						name: i ? this.$i18n.t('forms.steps', {step: i}) : this.$i18n.t('forms.scan'),
						label: this.label ? labels[phase2[i]] : ''
					});

				}

				return steps;

			}

		},

		watch: {

			application: {
				handler(val) {

					if (!val || !val.data || !val.data.id) {

						return;

					}

					this.applicationId = val.data.id;

				},
				deep: true,
				immediate: true
			}

		},

		methods: {

			compare(num) {

				let current = parseInt(this.current.toString().substr(0, 1));

				if (current < num) {

					return 1;

				}

				if (current > num) {

					return -1;

				}

				return 0;

			},

			getRoute(num) {

				let match = this.$route.name.match(/^(ApplicationStep|CoBorrowerStep|Assets|FinancingProgram|Sign|UploadFilesStep1)(.*?)$/);

				let defaultParams = {
					applicationId: this.applicationId
				};

				let params = (this.$route.params && this.$route.params.applicationId) ? this.$route.params: defaultParams;

				if (!match || !match[1]) {

					return {
						name: 'ApplicationStep1',
						params
					};

				}

				let beginning = match[1];

				if (phase2.includes(match[1])) {

					return {
						name: phase2[ num ],
						params
					};

				}

				return {
					name: beginning + num,
					params
				};

			}

		}

	};

</script>

<style lang="scss" scoped>

	.steps-form .steps-row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: $blue;

		&:before {
			top: 20px;
			left: 10px;
			right: 10px;
			width: auto;
			height: 2px;
			background-color: $blue;
		}

		.steps-step {
			display: block !important;

			@media all and (-ms-high-contrast:none) {
				margin-left: auto;
				margin-right: auto;
			}

			.btn-circle {
				opacity: 1 !important;
				font-size: 1rem;
				font-weight: bold;
				padding: 8px 0;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 2rem;
				box-shadow: none;
				margin-right: 0;
				margin-left: 0;

				&.btn-info {
					padding: 9px 0;

					&:before {
						position: absolute;
						top: 20px;
						left: 10px;
						right: 10px;
						width: auto;
						height: 2px;
						background-color: $blue;
					}
				}

				&.btn-white.btn-outline-primary {
					&, &:hover, &:focus, &:active {
						background: $white !important;
					}
				}
			}

		}

	}

</style>
