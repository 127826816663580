<template>

	<div id="app">

		<div class="fatal-error" ref="fatalError" v-if="fatalError">{{ $t('fatalError.prefix') + fatalError }}</div>

		<loading :active="loading" color="#008093"></loading>

		<vue-snotify></vue-snotify>

		<router-view :key="$route.fullPath" />

	</div>

</template>

<script>

	import { mapState } from 'vuex';
	import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

	export default {

		name: 'App',

		computed: {

			...mapState({
				loading: state => state.app.loading,
				fatalError: state => state.app.fatalError
			})

		},

		watch: {

			fatalError(val) {

				if (val) {

					disableBodyScroll(this.$refs.fatalError ? this.$refs.fatalError.$el : null, {reserveScrollBarGap: true});

				} else {

					clearAllBodyScrollLocks();

				}

			}

		},

		mounted() {

			var tag = document.createElement("title");
			var text = document.createTextNode("loading icon");
			tag.appendChild(text);
			var element = document.getElementsByClassName('vld-icon')[0];
			element.children[0].appendChild(tag);
			
		},

	};

</script>

<style lang="scss" scoped>

	/deep/ {

		.was-validated {

			.form-control.is-valid {
				border-color: $success;
				background-position: center right 0.7em;
			}

			.form-control.is-invalid {
				border-color: $danger;
				background-position: center right 0.7em;
			}

		}

	}

</style>
