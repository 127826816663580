<template>

	<mdb-container class="mt-5">

		<h4 class="font-weight-bold text-dark">{{ $t('uploads.' + uploadType) }}</h4>
		<p class="mb-4">{{ $t('uploads.' + uploadType + '-description') }}</p>
		<p class="mb-4">{{ $t('uploads.jpg-notice') }}</p>

		<div class="upload" :class="$refs.upload && $refs.upload.dropActive && 'drop-active'">

			<div class="upload-drop text-center p-5">

				<h3 class="text-info">{{ $t('uploads.dragDrop') }}</h3>
				<p class="mb-4">{{ $t('uploads.dragExplanation') }}</p>
				
				<p class="mb-4 uppercase">{{ $t('common.or') }}</p>

				<div class="btns mt-3">

					<file-upload
						:custom-action="customUpload"
						class="btn btn-info"
						type="button"
						:multiple="false"
						:drop="true"
						:drop-directory="true"
						v-model="files"
						accept=".pdf, .jpg"
						ref="upload"
						@input-file="inputFile"
						:timeout="600 * 100000"
					><i class="fa fa-plus pr-2"></i>Choose files</file-upload>

					<button
						type="button"
						class="btn btn-success"
						v-if="!$refs.upload || !$refs.upload.active"
						@click.prevent="$refs.upload.active = true"
					><i class="fa fa-arrow-up pr-2" aria-hidden="true"></i>Start Upload</button>

					<button
						type="button"
						class="btn btn-danger"
						v-else
						@click.prevent="$refs.upload.active = false"
					><i class="fa fa-stop pr-2" aria-hidden="true"></i>Stop Upload</button>

				</div>

			</div>

			<ul class="upload-list" v-if="files.length">
				<li v-for="(file, index) in files" :key="file.id">
					<span :index="index">{{ file.name }}</span>
					<span v-if="file.error" class="badge badge-danger ml-3">{{ file.error }}</span>
					<span v-else-if="file.success" class="badge badge-success ml-3">success</span>
					<span v-else-if="file.active" class="badge badge-info ml-3">active</span>
				</li>
			</ul>

		</div>

		<div class="form-group button-row mt-3">

			<submit-btn
				type="submit"
				:loading="goingBack"
				@click="goBack({name: 'UploadFilesStep1', params: $route.params})"
			>{{ $t('forms.back') }}</submit-btn>

		</div>

	</mdb-container>

</template>

<script>

	import FileUpload from 'vue-upload-component';
	import { upload } from '../../api';
	import FormsMixin from './ApplicationForms/FormsMixin';


	export default {

		mixins: [
			FormsMixin
		],

		components: {
			FileUpload
		},

		data() {

			return {
				uploadType: this.$route.params.type,
				url: process.env.VUE_APP_ENDPOINT,
				files: []
			};

		},

		methods: {

			async customUpload(file, component) {
				
				console.log('customUpload');
				console.log('file', file);

				let payload = {
					uploadType: this.$route.params.type,
					id: this.$route.params.applicationId
				};
				
				let result = await upload(payload, file);

				console.log('result', result);
				
			},

			inputFile(newFile, oldFile) {

				if (
					(newFile && !oldFile) // add
					||
					(newFile && oldFile) // update
				) {
					
					// add
					console.log('add', newFile);
					
					let type = newFile.type;
	
					console.log('type', type);
						
						if (

							!type

							||

							(
								type !== 'application/pdf'
								&& type !== 'image/jpeg'
							)

						) {

							console.log('wrong type');
							this.files = [];
							this.showErrorAlert(this.$i18n.t('uploads.fileTypesAccepted'));

						}
				
				}

				if (!newFile && oldFile) {

					// remove
					console.log('remove', oldFile);
				
				}

			}

		}

	};

</script>

<style lang="scss" scoped>

	.upload {
		border: 1px dashed $border-color;
		border-radius: 20px;
		background: white;

		&.drop-active {
			background: transparentize($success-color, 0.2);
		}

		.upload-list {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: block;
				width: 100%;
				border-top: 1px solid $border-color;
				padding: 15px;
			}
		}

	}

</style>