<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="4" :total="4" label="Upload Files" />

		<mdb-container v-if="isFilesUploaded" class="mt-5">
			<mdb-card>
				<mdb-card-body class="card-body-padding text-center">

					<div class="d-block mb-5 text-center">
						<div class="snotify-icon snotify-icon--success "></div>
					</div>

					<div class="mt-5 pt-3">
						<p class="text-primary"><strong>{{ $t("uploads.docsUploaded") }}</strong></p>
					</div>

				</mdb-card-body>
			</mdb-card>
		</mdb-container>

		<mdb-container v-if="!isFilesUploaded" class="mt-5">

			<h4 class="font-weight-bold text-dark">{{ $t('uploads.step1Title') }}</h4>
			<p class="mb-4">{{ $t('uploads.step1Paragraph') }}</p>
			<p class="mb-4">{{ $t('uploads.step1Paragraph2') }}</p>

			<mdb-row>

				<mdb-col md="12" class="mb-2" v-for="(upload, name) in uploads" :key="'col-' + name">

					<router-link
						tag="div"
						:to="{
							name: 'UploadFilesStep2',
							params: {
								id: $route.params && $route.params.id,
								type: kebabCase(name)
							}
						}"
					>
						<btn
							tag="a"
							:color="vModels[name] ? 'success' : (uploads[name]['optional'] ? 'primary' : 'warning')"
							size="lg"
							block
							class="big-btn"
							style="min-height: 100px"
						>

							<span class="btn-title">{{ $t('uploads.' + name) }}</span>

							<span class="smaller-btn-line d-flex flex-row justify-content-between">
								<!-- <span class="text-left"><span class="text-info">0</span> {{ $tc('uploads.fileCount', {count: 0}) }}</span> -->
								<span class="text-right" :class="completedSteps(name) && 'progress-complete'">
									<!-- <radial-progress-bar
										:diameter="28"
										:stroke-width="2"
										inner-stroke-color="#FFFFFF"
										start-color="#008093"
										stop-color="#008093"
										:completed-steps="completedSteps(name)"
										:total-steps="totalSteps(name)"
									>
										<icon name="check" type="ma" v-if="completedSteps(name)" />
									</radial-progress-bar> -->
								</span>
							</span>

						</btn>
					</router-link>

				</mdb-col>

			</mdb-row>

			<br />

			<p class="mb-4 ">

				{{ $t('uploads.instructions') }}
				
				<span style="font-weight:500">
					{{ $t('uploads.instructions2') }}
				</span>

			</p>

			<div class="form-group button-row">

				<submit-btn
					:loading="goingBack"
					@click="goBack({name: 'Sign', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<btn
					type="button"
					color="primary"
					size="lg"
					rounded
					block
					@click="$router.push({name: 'Dashboard'})"
				>{{ $t('newApplication.finishLater') }}</btn>

				<btn
					type="submit"
					color="primary"
					size="lg"
					rounded
					block
					v-if="vModels['billOfSale'] && vModels['voidCheque'] && vModels['warrantyInfo']"
					@click="uploadToEdgeTrigger()"
				>{{ $t('uploads.uploadToEdge') }}</btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	// import ApplicationMixin from '../ApplicationForms/Application/ApplicationMixin';
	import RadialProgressBar from 'vue-radial-progress';
	import { kebabCase, camelCase } from 'change-case';
	import FormsMixin from './ApplicationForms/FormsMixin';
	import { uploadToEdge, checkSignStatus, checkUploadsStatus } from '../../api';

	export default {

		components: {
			RadialProgressBar
		},

		mixins: [
			FormsMixin
		],

		data() {

			return {
				uploads: {
					billOfSale: {
						optional: false
					},
					voidCheque: {
						optional: false
					},
					proofOfOwnership: {
						optional: true
					},
					warrantyInfo: {
						optional: false
					},
					otherSupportingDocs: {
						optional: true
					}
				},
				isFilesUploaded: false,
				vModels: {
					billOfSale: null,
					voidCheque: null,
					proofOfOwnership: null,
					warrantyInfo: null,
					otherSupportingDocs: null,
				}
			};

		},

		mounted() {

			this.checkUploadStatus();

		},

		methods: {

			kebabCase: val => kebabCase(val),

			getRoute(name) {

				return {
					name: 'UploadFilesStep2',
					params: {
						id: this.$route.params && this.$route.params.id,
						type: kebabCase(name)
					}
				};

			},

			completedSteps(name) {

				return (name === 'otherUpload1') ? 1 : 0;

			},

			totalSteps(name) {

				console.log(name);

				return 1;

			},

			async uploadToEdgeTrigger() {

				console.log(this.application.data.id);
				
				this.saveLoading(true);

				let result = await uploadToEdge({id: this.application.data.id}).catch(error => {

					let errorMsg = error.toString();

					if (error.response && error.response.data && error.response.data.errorMessage) {

						errorMsg = error.response.data.errorMessage;

					}

					if (errorMsg) {

						this.showErrorAlert(errorMsg);

						this.checkUploadStatus();

					}

				});

				console.log('result', result);

				if (result == "Success") {

					this.showSuccessAlert(this.$i18n.t('uploads.successfulUpload'));

					this.isFilesUploaded = true;

				} else {

					this.checkUploadStatus();

					this.errorAlert = await this.showErrorAlert(result);

				}

				this.saveLoading(false);

			},

			async checkStatus() {

				this.saveLoading(true);

				var signResult = await checkSignStatus({
					id: this.$route.params.applicationId
				});

				if (signResult.data.results != "completed") {

					this.errorAlert = await this.showErrorAlert(this.$i18n.t('uploads.signDocBefore'));

					return this.$router.push({
						name: 'Sign',
						params: this.$route.params
					});

				}

				console.log('signResult', signResult);

				this.saveLoading(false);

			},

			async checkUploadStatus() {

				this.saveLoading(true);

				let checkUploadsStatusResult = await checkUploadsStatus(this.$route.params.applicationId);

				// this.vModels = checkUploadsStatusResult.data;

				Object.keys(this.vModels).forEach(element => {
					this.vModels[element] = null;
				});

				if (typeof checkUploadsStatusResult.data === 'object') {
					Object.keys(checkUploadsStatusResult.data).forEach(e => {
						this.vModels[camelCase(e)] = checkUploadsStatusResult.data[e];
					})
				}

				console.log('checkUploadsStatusResult', checkUploadsStatusResult);
				console.log('this.vModels', this.vModels);

				this.saveLoading(false);

			}

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ .big-btn {
		position: relative;

		.btn-title {
			display: block;
			padding: 1rem 0.5rem 2rem;
		}

		.smaller-btn-line {
			text-transform: capitalize;
			font-size: 0.8rem;

			.text-left {
				padding: 0.25rem 0;
			}

			.radial-progress-inner {
				.mi-check {
					font-size: 1rem;
					color: $info;
				}
			}
		}
	}

	.btn-info {
		padding: 9px 0;

		&:before {
			position: absolute;
			top: 20px;
			left: 10px;
			right: 10px;
			width: auto;
			height: 2px;
			background-color: $blue;
		}
	}

	.d-block {

		display: block;
		text-align: center;
		margin: auto;
		margin-bottom: 500px;

		.snotify-icon {

			position: absolute;
			right: unset;
			left: 47%;
			top: unset;
			margin: auto;
			line-height: 0;
			-webkit-transform: unset;
			transform: unset;
			max-height: 48px;
			max-width: 48px;
			width: 100%;
			height: 100%;

		}

	}

</style>
