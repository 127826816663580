<template>
	
	<div v-if="isMultiWrapper" :id="idCPU" :class="wrapperClasses">
		
		<label v-if="label" :class="labelClasses" :for="idCPU">{{ label }}</label>
		
		<template v-for="(option, index) in options">
			
			<my-radio
				v-bind="$attrs"
				:key="idCPU + '-' + index"
				:id="idCPU + '-' + index"
				:name="nameCPU"
				:label="option.text"
				:radio-value="option.value"
				:value="value"
				:bootstrap="bootstrap"
				:custom-validation="!isValid"
				:is-valid="isValid"
				:invalid-feedback="invalidFeedback"
				v-on="inputListeners"
			>{{ option.text }}
			</my-radio>
			
		</template>
		
		<slot></slot>
		
		<div v-if="invalidFeedback" class="invalid-feedback">
			{{ invalidFeedback }}
		</div>
		
	</div>
	
	<div v-else-if="isMaterial" :class="wrapperClasses">
		
		<my-input
			v-bind="$attrs"
			type="radio"
			:name="nameCPU"
			:id="idCPU"
			:label="label"
			:radio-value="radioValue"
			:checked="inputChecked"
			:custom-validation="!isValid"
			:is-valid="isValid"
			:invalid-feedback="invalidFeedback"
			v-on="inputListeners"
		/>
		
	</div>
	
	<div v-else :class="wrapperClasses">
		
		<input
			type="radio"
			class="custom-control-input"
			:name="nameCPU"
			:id="idCPU"
			:checked="inputChecked"
			:value="radioValue"
			:custom-validation="!isValid"
			:is-valid="isValid"
			:invalid-feedback="invalidFeedback"
			v-on="inputListeners"
		/>
		
		<label
			class="custom-control-label"
			:for="idCPU"
		>{{ label }}</label>
		
	</div>
	
</template>

<script>
	
	import { kebabCase } from 'change-case';
	import MyInput from './Input';
	import MyRadio from './Radio';
	
	export default {
		
		name: 'MyRadio',
		
		components: {
			MyInput,
			MyRadio
		},
		
		inheritAttrs: false,
		
		props: {
			
			bootstrap: {
				type: Boolean,
				default: false
			},
			
			label: {
				type: [String, Boolean],
				default: null
			},
			
			labelClass: {
				type: String,
				default: null
			},
			
			name: {
				type: String,
				default: null
			},
			
			id: {
				type: String,
				default: null
			},
			
			wrapperClass: {
				type: String,
				default: null
			},
			
			options: {
				type: [Object, Array],
				default: null
			},
			
			checked: {
				type: Boolean,
				default: false
			},
			
			value: {
				type: [String, Number, Boolean, Event],
				default: ''
			},
			
			radioValue: {
				type: String
			},
			
			customValidation: {
				type: Boolean
			},
			
			isValid: {
				type: Boolean
			},
			
			invalidFeedback: {
				type: [String, Boolean],
				default: false
			}
			
		},
		
		data() {
			
			return {
				innerValue: this.value,
				innerChecked: this.checked
			};
			
		},
		
		computed: {
			
			isMaterial() {
				
				return !this.bootstrap;
				
			},
			
			isMultiWrapper() {
				
				return (this.options);
				
			},
			
			inputChecked() {
				
				if ((this.value === true || this.value === this.radioValue) || this.innerChecked === true) {
					
					return true;
					
				}
				
				return false;
				
			},
			
			nameCPU() {
				
				return (this.name) ? this.name : 'field-' + this._uid;
				
			},
			
			idCPU() {
				
				return (this.id) ? this.id : kebabCase(this.nameCPU);
				
			},
			
			labelClasses() {
				
				return [
					this.labelClass
				];
				
			},
			
			wrapperClasses() {
				
				if (this.isMultiWrapper) {
					
					return [
						'form-group',
						'field-type-radio',
						this.$attrs.required && 'required',
						this.customValidation ? this.isValid ? 'is-valid' : 'was-validated is-invalid' : false,
						this.wrapperClass
					];
					
				}
				
				return [
					this.bootstrap && 'custom-control custom-radio',
					this.wrapperClass
				];
				
			},
			
			inputListeners() {
				
				let _self = this;
				
				return Object.assign({},
					this.$listeners,
					{
						
						blur: function (event) {
							
							_self.$emit('blur', event);
							
						},
						
						change: function (event) {
							
							if (event && event.target && event.target.value) {
								
								return _self.$emit('change', event.target.value);
								
							}
							
							_self.$emit('change', event);
							
						},
						
						focus: function (event) {
							
							_self.$emit('focus', event);
							
						},
						
						input: function (event) {
							
							if (event && event.target && event.target.value) {
								
								return _self.$emit('input', event.target.value);
								
							}
							
							_self.$emit('input', event);
							
						}
						
					});
				
			}
			
		}
	
	};
	
</script>
