<template>

	<div class="auth-layout" :id="pageId">

		<div class="full-screen">
			<view-wrapper class="">
				<mdb-mask class="d-flex justify-content-center align-items-center">

					<main>

						<mdb-container fluid>
							<mdb-row>
								<mdb-col>

									<form @submit.prevent="triggerChildSubmit" :name="pageId" v-show="ready === true">

										<mdb-card class="bg-1 white-text mxw-400px">
											<mdb-card-body>

												<p class="main-logo-wrapper">
													<icon type="uei-icon" name="UEI-logo" :aria-label="$t('logo')" role="logo" class="main-logo"></icon>
												</p>

												<router-view :key="$route.fullPath" ref="child" @ready="ready = $event" />

											</mdb-card-body>
										</mdb-card>

									</form>

								</mdb-col>
							</mdb-row>
						</mdb-container>

					</main>

				</mdb-mask>
			</view-wrapper>
		</div>

	</div>

</template>

<script>

	import { kebabCase } from 'change-case';

	export default {

		data() {

			return {
				ready: false
			};

		},

		computed: {

			pageId() {

				return kebabCase(this.$route.name);

			}

		},

		methods: {

			triggerChildSubmit(e) {

				let event = document.createEvent("Event");
				event.initEvent("submit", false, true);

				// this way the form wraps the whole card (for mobile zooming reasons)
				// the submit event can be caught by the parent tag of the child component marked ref="child"
				this.$refs.child.$el.dispatchEvent(event);

			}

		}

	};

</script>

<style lang="scss" scoped>

	.full-screen {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 10000;
		height: 100vh;
		width: 100%;
	}

	.view {
		height: 100vh;
	}

</style>
