import { formatCurrency } from '../../../elements/globalDirectives/CurrencyLocale';
import { mapState } from 'vuex';

export default {

	data() {

		return {
			values: {}
		};

	},

	computed: {

		...mapState({
			locale: state => state.app.locale
		})

	},

	methods: {

		setValues() {

			let data;

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				data = this.application.data;

			} else if (/^CoBorrowerStep(.*?)$/.test(this.$route.name)) {

				data = this.coBorrower.data;

			}

			if (!data) {

				return;

			}

			let values = {};

			// looping through fields to figure out if they have options in the config
			// this way the value is the option label instead of the option value
			Object.keys(data).forEach(name => {

				let optionSet = this.checkForOptions(name, {});
				optionSet = optionSet.options || optionSet;

				if (!optionSet || !Object.keys(optionSet).length && Object.keys(this.optionSets).includes(name)) {

					optionSet = this.optionSets[name];

				}

				if (!optionSet || !Object.keys(optionSet).length) {

					let result = this.findInConfig(name);

					optionSet = result && result.field && result.field.options;

				}

				if (optionSet && Object.keys(optionSet).length) {

					let optionLabel = optionSet[data[name]];

					if (optionLabel) {

						if (this.isLocalizedObject(optionLabel)) {

							optionLabel = this.getTextFromLocalizedObject(optionLabel);

						}

						values[name] = optionLabel;

					}

				}

				if (typeof values[name] === 'undefined') {

					values[name] = data[name];

				}

				switch (name) {

					case 'amountApproved':
					case 'rentalAmount':
					case 'amount':
					case 'grossIncome':
					case 'totalHousingCosts':
					case 'totalOtherMonthlyCosts':

						if (typeof values[name] !== 'undefined') {

							try {
								
								var moneyVal = formatCurrency(values[name], this.locale);

							} catch (error) {
								
							}

							if (moneyVal) {
								values[name] = moneyVal;
							}

						}

						break;

				}

			});

			this.$set(this.$data, 'values', values);

		},

		getGroupClasses(name) {

			return [
				'form-group static-form-group',
				this.application.errors[name] && 'was-validated is-invalid'
			];

		}

	}

};

