import { mapState } from 'vuex';
import ApplicationValidator from 'ApplicationValidator';
import Joi from 'joi';
import BaseMixin from './BaseMixin';
import Steps from './Steps';
import SubmitBtn from '../../../elements/components/SubmitBtn';
import { isObject, isArray, flattenObject } from '@incodeapps/js-utilities';
import { throttle } from 'throttle-debounce';

export default {

	mixins: [
		BaseMixin
	],

	components: {
		Steps,
		SubmitBtn
	},

	data() {

		return {
			vModels: {},
			optionals: [],
			options: {},
			errors: {},
			validated: false,
			enableOnly: {}
		};

	},

	computed: {

		...mapState({
			authUserVendor: state => state.auth.authUserVendor,
			validateAll: state => state.application.validateAll,
			optionSets: state => state.application.options
		}),

		isVendorFromQuebec() {

			return this.authUserVendor && this.authUserVendor.province === 'QC';

		},

		joiSchema() {

			// regex to read the name of the route and pick the part of the validation schema I need

			let match = this.$route.name.match(/^(Application|CoBorrower)Step(.*?)$/);
			let first = match[1].charAt(0).toLowerCase() + match[1].slice(1);
			let num = match[2];
			let schemaFromRoute = ApplicationValidator.get(first + '-' + num);
			let schemasFromRouteWithId = Object.assign({}, schemaFromRoute, ApplicationValidator.get('common'));

			return flattenObject(schemasFromRouteWithId, false);

		},

		countryValue() {

			if (this.vModels && this.vModels.country) {

				return this.vModels.country;

			}

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				if (this.application && this.application.data && this.application.data.country) {

					return this.application.data.country;

				}

			}

			if (/^CoBorrowerStep(.*?)$/.test(this.$route.name)) {

				if (this.coBorrower && this.coBorrower.data && this.coBorrower.data.country) {

					return this.coBorrower.data.country;

				}

			}

			return this.authUserVendor && this.authUserVendor.country;

		}

	},

	watch: {

		'vModels.country': {
			handler() {

				if (this.vModels) {

					Object.keys(this.vModels).forEach(key => {

						this.checkForOptions(key);

					});

				}

			},
			immediate: true
		},

		optionSets: {
			handler() {

				if (this.vModels) {

					Object.keys(this.vModels).forEach(key => {

						this.checkForOptions(key);

					});

				}

			}
		}

	},

	mounted() {

		if (this.validateAll) {

			let errors;

			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				errors = this.application.errors;

			} else if (/^CoBorrowerStep(.*?)$/.test(this.$route.name)) {

				errors = this.coBorrower.errors;

			}

			if (errors) {

				let response = {};

				Object.keys(this.vModels).forEach(i => {

					if (errors[i]) {

						response[i] = errors[i];

					}

				});


				this.$set(this.$data, 'errors', response);
				this.$set(this.$data, 'validated', true);

			}

		}

	},

	methods: {

		onChange: throttle(100, function (val) {

			// for MS Edge
			if (navigator.userAgent.indexOf('Edge') !== -1) {

				if (
					window.event
					&& window.event.type === 'change'
					&& window.event.target.name
					&& this.vModels[window.event.target.name] !== undefined
					&& this.vModels[window.event.target.name] !== val
				) {

					this.$set(this.vModels, window.event.target.name, val);

				}

			}

			this.$nextTick(() => {

				if (!this.validated && !this.validateAll) {

					return;

				}

				this.errors = this.validateRequiredFields(this.vModels);

			});

		}),

		getForm(key) {

			if (!this.formsJson) {

				return;

			}

			return this.formsJson[key];

		},

		getLabel(fieldName) {

			if (/^row/.test(fieldName)) {

				return '';

			}

			if (this.countryValue === 'USA') {

				switch (fieldName) {

					case 'province':
						return this.$i18n.t('newApplication.state');

					case 'postal':
						return this.$i18n.t('newApplication.zipCode');

					case 'prevProvince':
						return this.$i18n.t('newApplication.prevState');

					case 'prevPostal':
						return this.$i18n.t('newApplication.prevZipCode');

				}

			}

			return this.$i18n.t('newApplication.' + fieldName);

		},

		getMask(name, data) {

			if (['postal', 'prevPostal'].includes(name)) {

				switch (this.countryValue) {

					case 'US':
					case 'USA':
						return ['#####', '#####-####'];

					default:
					case 'CA':
					case 'CAN':
						return 'A#A #A#';

				}

			}

			return data.mask;

		},

		checkForOptions(name, data) {

			let val;

			if (['province', 'prevProvince'].includes(name)) {

				let isUSA = (['US', 'USA'].includes(this.countryValue));

				let countryKey = isUSA ? 'USA' : 'CA';
				// let country = (name === 'prevProvince') ? this.vModels.prevCountry: this.vModels.country;

				val = this.optionSets.locations && this.optionSets.locations[countryKey] && this.optionSets.locations[countryKey].provinces;

				if (!val) {

					val = isUSA ? this.optionSets.states : this.optionSets.provinces;

				}

			} else if (name === 'coborrowerRelationship') {

				val = this.optionSets['relationships'];

			} else if (Object.keys(this.optionSets).includes(name)) {

				val = this.optionSets[name];

			}

			// only for main applicant current address
			if (/^ApplicationStep(.*?)$/.test(this.$route.name)) {

				if (name === 'province') {

					let onlyProv = this.authUserVendor && this.authUserVendor.province;

					if (val && onlyProv && val[onlyProv]) {

						this.enableOnly[name] = onlyProv;

					}

				} else if (name === 'country') {

					let isUSA = (['US', 'USA'].includes(this.authUserVendor && this.authUserVendor.country));
					let countryKey = isUSA ? 'USA' : 'CA';

					this.enableOnly[name] = countryKey;

				}

			}

			if (isObject(val) || isArray(val)) {

				this.$set(this.options, name, val);

				if (isObject(data)) {

					data.options = this.options[name];

				}

				if (this.vModels && this.vModels[name] !== undefined) {

					let vModelVal = this.vModels && this.vModels[name];
					let exist;

					if (isArray(val)) {

						exist = val.filter(item => item === vModelVal);

					} else {

						exist = Object.keys(val).filter(item => item === vModelVal);

					}

					if (!exist.length) {

						let firstOptionVal = this.getFirstOption(val);

						this.$set(this.vModels, name, firstOptionVal);
						this.manuallySetSelectValue(name, this.vModels[name]);

					}

				}

			}

			this.$nextTick(function () {

				if (this.vModels && this.vModels[name] !== undefined) {

					this.manuallySetSelectValue(name, this.vModels[name]);

				}

			});

			return data;

		},

		getFirstOption(options) {

			let first;

			Object.keys(options).forEach(optionKey => {

				if (first) {

					return;

				}

				first = optionKey;

			});

			return first;

		},

		validateRequiredFields(fields, scroll = false) {

			let response = false;

			let schema = Object.assign({}, this.joiSchema);

			if (this.isVendorFromQuebec && this.$route.name === 'ApplicationStep3.1') {

				schema = Object.assign(schema, ApplicationValidator.get('application-3.1quebecOnly'));

			}

			if (this.isScanPage) {

				schema = {
					firstName: Joi.string().allow(''),
					lastName: Joi.string().allow(''),
					dob: Joi.string().allow(''),
					address1: Joi.string().allow(''),
					postal: Joi.string().allow(''),
					city: Joi.string().allow(''),
					province: Joi.string().allow(''),
					country: Joi.string().allow('')
				};

			}

			let errors = ApplicationValidator.validate(fields, schema, this.locale);

			if (errors) {

				// console.error(JSON.stringify(errors, null, 2));

				Object.keys(errors).forEach(i => {

					if (!isObject(response)) {

						response = {};

					}

					response[errors[i].key] = errors[i].message;

				});

			}

			this.validated = true;

			if (scroll) {

				setTimeout(function () {

					let invalids = document.querySelectorAll(".was-validated, .md-invalid");

					if (invalids && invalids[0]) {

						this.$scrollTo(invalids[0]);

					}

				}.bind(this), 150);

			}

			return response;

		},

		isLocalizedObject(val) {

			if (!val || val.constructor !== Object) {

				return false;

			}

			return (val[this.locale] || val[this.defaultLocale]);

		},

		getTextFromLocalizedObject(val, defaultOnly = false) {

			if (!this.isLocalizedObject(val)) {

				return val;

			}

			return (!defaultOnly && val[this.locale]) || val[this.defaultLocale];

		},

		findInConfig(fieldName) {

			if (!this.formsJson) {

				return false;

			}

			let result;

			Object.keys(this.formsJson).forEach(section => {

				if (result) {

					return;

				}

				Object.keys(this.formsJson[section]).forEach(name => {

					if (result) {

						return;

					}

					if (this.formsJson[section][name].type === 'parent') {

						Object.keys(this.formsJson[section][name].fields).forEach(childName => {

							if (result) {

								return;

							}

							if (fieldName === childName) {

								result = {
									section,
									field: this.formsJson[section][name].fields[childName]
								};

							}

						});

					}

					if (fieldName === name) {

						result = {
							section,
							field: this.formsJson[section][name]
						};

					}

				});


			});

			return result;

		},

		setDefaultValues(vModels, defaultValues) {

			Object.keys(vModels).forEach(i => {

				this.setDefaultValue(i, defaultValues);

			});

		},

		setDefaultValue(fieldName, defaultValues) {

			let val;

			if (!isObject(defaultValues) || (defaultValues[fieldName] === undefined || defaultValues[fieldName] === null)) {

				if (['country', 'prevCountry'].includes(fieldName) && this.authUserVendor.country) {

					val = this.authUserVendor.country;

				}

				if (['province', 'prevProvince'].includes(fieldName) && this.authUserVendor.province) {

					val = this.authUserVendor.province;

				}

				if (val) {

					this.$set(this.vModels, fieldName, val);
					this.manuallySetSelectValue(fieldName, val);

				} else {

					//make sure money fields have a string as default and not null
					let moneyFields = [
						'amountApproved',
						'rentalAmount',
						'amount',
						'grossIncome',
						'totalHousingCosts',
						'totalOtherMonthlyCosts'
					];

					if (moneyFields.includes(fieldName)) {
						this.$set(this.vModels, fieldName, '');
					}

				}

				return;

			}

			val = defaultValues[fieldName];

			this.$set(this.vModels, fieldName, val);

			this.manuallySetSelectValue(fieldName, val);

		},

		setDebugValues(vModels, defaultValues) {

			if (!process.env.VUE_APP_FORMS_PRE_FILLED) {

				return;

			}

			Object.keys(vModels).forEach(i => {

				this.setDebugValue(i, defaultValues);

			});

		},

		setDebugValue(fieldName, defaultValues) {

			if (!process.env.VUE_APP_FORMS_PRE_FILLED) {

				return;

			}

			if (
				!defaultValues[fieldName]
				&& (!this.optionals || !this.optionals.includes(fieldName))
			) {

				let val;

				switch (fieldName) {

					case 'grossIncome':
					case 'totalHousingCosts':
					case 'totalOtherMonthlyCosts':
						val = '0.00';

					case 'applicationType':
						val = 102;
						break;

					case 'amount':
						val = '50.00';
						break;

					case 'curAddressLess':
					case 'creditReleaseObtained':
					case 'hasCoApplicant':
					case 'isImmediateFamily':
						val = 'Yes';
						break;

					case 'email':
						val = 'email@example.com';
						break;

					case 'primaryPhone':
					case 'businessPhone':
						val = '(555) 555-5555';
						break;

					case 'prevProvince':
					case 'province':
						val = 'ON';
						break;

					case 'prevCountry':
					case 'country':
						val = 'CA';
						break;

					case 'prevPostal':
					case 'postal':
						val = 'G1Q 1Q9';
						break;

					case 'dob':
						val = '12/12/1980';
						break;

					case 'titleOwner':
						val = 'O';
						break;

					case 'coborrowerRelationship':
						val = 'Spouse';
						break;

					default:
						val = fieldName;
						break;

				}

				if (val) {

					this.$set(this.vModels, fieldName, val);

					this.manuallySetSelectValue(fieldName, val);

				}

			}

		},

		manuallySetSelectValue(fieldName, val) {

			// Hack to make the fields use a default value

			let elm = document.querySelector(`select[name=${fieldName}]`);

			if (elm) {

				elm.childNodes.forEach(child => {

					if (child.value == val) {

						child.selected = true;

					}

				});

				if (val !== elm.value) {

					elm.value = val;
					elm.dispatchEvent(new CustomEvent('change', elm));

				}

			}


		}

	}

};
