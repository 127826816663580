<template>

	<form @submit.prevent="onSubmit" novalidate>

		<steps :current="3.1" :total="4" />

		<mdb-container>

			<h4 class="font-weight-bold text-dark mb-4 px-4">{{ $t('newApplication.mainApplicant') }}</h4>

			<mdb-card>
				<mdb-card-body>

					<template v-for="(fieldData, fieldName) in getForm('3.1')">

						<form-field
							v-bind="checkForOptions(fieldName, fieldData)"
							v-model="vModels[fieldName]"
							:name="fieldName"
							:label="getLabel(fieldName)"
							:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
							:error="errors[fieldName]"
							:locale="locale"
							bootstrap
							@change="onChange"
						></form-field>

					</template>

					<template v-if="isVendorFromQuebec">

						<template v-for="(fieldData, fieldName) in getForm('3.1quebec')">

							<form-field
								v-bind="checkForOptions(fieldName, fieldData)"
								v-model="vModels[fieldName]"
								:name="fieldName"
								:label="getLabel(fieldName)"
								:options="(options[fieldName]) ? options[fieldName] : fieldData.options"
								:required="isVendorFromQuebec"
								:error="errors[fieldName]"
								:locale="locale"
								:mask="getMask(fieldName, fieldData)"
								bootstrap
								@change="onChange"
							>

								<template
									v-if="fieldData.type === 'parent'"
									v-slot:fields
								>

									<form-field
										v-for="(childData, childName) in fieldData.fields"
										:key="fieldName + '-' + childName"
										v-bind="childData"
										v-model="vModels[childName]"
										:name="childName"
										:label="getLabel(childName)"
										:options="(options[childName]) ? options[childName] : childData.options"
										:error="errors[childName]"
										:locale="locale"
										:mask="getMask(childName, childData)"
										bootstrap
										@change="onChange"
									></form-field>

								</template>

							</form-field>

						</template>

					</template>

				</mdb-card-body>
			</mdb-card>

			<div class="form-group button-row">

				<submit-btn
					type="button"
					color="primary"
					:loading="goingBack"
					@click="goBack({name: 'ApplicationStep2', params: $route.params})"
				>{{ $t('forms.back') }}</submit-btn>

				<submit-btn type="submit" :loading="submitting">{{ $t('forms.next') }}</submit-btn>

			</div>

		</mdb-container>

	</form>

</template>

<script>

	// import { mapState } from 'vuex';
	import ApplicationMixin from './ApplicationMixin';

	export default {

		mixins: [
			ApplicationMixin
		],

		data() {

			return {
				vModels: {
					firstName: '',
					lastName: '',
					sin: '',
					dob: '',
					primaryPhone: '',
					mobilePhone: '',
					email: '',
					employer: '',
					businessPhone: ''
				},
				optionals: [
					'mobilePhone',
					'sin',
					'referral',
					// 'referralOther'
				]
			};

		},

		watch: {

			'vModels.referral': {

				async handler(val) {

					let referralOther = document.getElementById('referral-other');

					if (val == 'other') {

						this.$set(this.vModels, 'referralOther', '');
						referralOther.parentElement.style.display = 'block';

					} else {
						
						referralOther.parentElement.style.display = 'none';

					}
					
				}

			},

		},

		created() {

			if (this.isVendorFromQuebec) {

				this.$set(this.vModels, 'grossIncome', '');
				this.$set(this.vModels, 'totalHousingCosts', '');
				this.$set(this.vModels, 'totalOtherMonthlyCosts', '');
				this.$set(this.vModels, 'referral', '');

			}

		},

		mounted() {

			let referralOther = document.getElementById('referral-other');
			referralOther.parentElement.style.display = 'none';

		}

	};

</script>

<style lang="scss" scoped>

	/deep/ .quebec-required-fields {

		& > label {
			color: $body-color;
			margin-bottom: 1rem;
		}

	}

</style>
