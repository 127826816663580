<template>

	<mdb-container class="mt-5">

		<mdb-card>
			<mdb-card-body class="card-body-padding text-center">

				<p class="mb-4">
					<icon name="sad" type="uei-icon" class="text-primary huge-uei-icon">
						<span class="path1"></span>
						<span class="path2"></span>
						<span class="path3"></span>
						<span class="path4"></span>
						<span class="path5"></span>
						<span class="path6"></span>
						<span class="path7"></span>
					</icon>
				</p>

				<h3 class="font-weight-bold uppercase text-danger mt-4">{{ $t('newApplication.creditBureauTitle') }}</h3>

				<p class="my-4 max-width-p">{{ $t('newApplication.creditBureauText') }}</p>

			</mdb-card-body>
		</mdb-card>

		<div class="form-group button-row">

			<btn
				type="button"
				color="primary"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'Dashboard'})"
			>{{ $t('newApplication.homeBtn') }}</btn>

			<btn
				type="button"
				color="info"
				size="lg"
				rounded
				block
				@click="$router.push({name: 'ApplicationStep1', params: $route.params})"
			>{{ $t('newApplication.creditBureauFormBtn') }}</btn>

		</div>

	</mdb-container>

</template>

<script>

	import BaseMixin from './BaseMixin';

	export default {

		mixins: [
			BaseMixin
		]

	};

</script>

<style lang="scss" scoped>

</style>
